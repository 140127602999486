import React from "react";
import parse from "html-react-parser";

import { Imgs, Texts } from "../../data/Data";
import { map } from "lodash/fp";

export const About = () => {
  return (
    <main className="about">
      <div className="about-content">
        <h2>{Texts.about.title}</h2>
        <h5>{parse(Texts.about.subtitle)}</h5>
        {map.convert({ cap: false })((paragraph, k) => (
          <p className="about-content-paragraphs" key={k}>
            {parse(paragraph)}
          </p>
        ))(Texts.about.paragraphs)}
      </div>
      <div className="about-image">
        <img className="about-image-img" alt="" src={Imgs.about.image} />
      </div>
    </main>
  );
};

export default About;
