// const API_LOCALHOST = "http://vlvl-back.localhost/vlvl/api";
export const API_PRE = "https://2024.vivalavidaloca.com/vlvl/api";

const API_DEFAULT = API_PRE;
// const API_DEFAULT = API_LOCALHOST;

export const API_CHANNELS = API_DEFAULT + "/canales";
export const API_CHANNELS_CITIES_FILTERS = API_DEFAULT + "/provsmunichannels";
export const API_CHANNEL = API_DEFAULT + "/canales/:id";
export const API_CHANNEL_EDIT = API_DEFAULT + "/canales/edit/:id";
export const API_CHANNEL_NEW = API_DEFAULT + "/canales/new";
export const API_CHANNEL_MEDIA = API_DEFAULT + "/canales/media/:id";
export const API_MANAGER = API_DEFAULT + "/anunciantes/:id";
export const API_MANAGER_EDIT = API_DEFAULT + "/anunciantes/edit/:id";
export const API_USER = API_DEFAULT + "/users/:id";
export const API_USER_EDIT = API_DEFAULT + "/users/edit/:id";
export const API_USER_ACTION = API_DEFAULT + "/actions";
export const API_USER_EDIT_AVATAR = API_DEFAULT + "/users/edit/:id/avatar";
export const API_LOGIN = API_DEFAULT + "/login";
export const API_PASSWORD_RECOVERY = API_DEFAULT + "/password-recovery";
export const API_SIGNUP = API_DEFAULT + "/signup";
export const API_SIGNUP_USER = API_DEFAULT + "/anunciantes/add";
export const API_SIGNUP_MANAGER = API_DEFAULT + "/users/add";
export const API_CREDITS = API_DEFAULT + "/credits";
export const API_CREDITS_BUY = API_DEFAULT + "/credits/buy";
export const API_CREDITS_USE = API_DEFAULT + "/credits/use";
export const API_PROVINCES_CITIES = API_DEFAULT + "/provsmuni";
