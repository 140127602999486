const Credits = [
  {
    credits: 5,
    price: 500,
    promotion: false,
  },
  {
    credits: 10,
    price: 900,
    promotion: 100,
  },
  {
    credits: 15,
    price: 1200,
    promotion: 300,
  },
  {
    credits: 20,
    price: 1500,
    promotion: 500,
  },
];

export default Credits;
