import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Label,
  Dropdown,
  TextArea,
  Checkbox,
  Button,
  Icon,
  ButtonGroup,
  Popup,
} from "semantic-ui-react";
import { Texts } from "../data/Data";
import propTypes from "prop-types";
import _ from "lodash/fp";

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const FormAvatar = ({ fieldData: { name, value, texts, onChange } }) => {
  const fileInputRef = useRef(null);
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        const base64Image = await convertBase64(selectedFile);
        onChange(null, { name, value: base64Image });
      } else {
        alert(texts.error);
      }
    }
  };
  return (
    <div className="account-user-avatar">
      <Label>{texts.label}</Label>
      <div className="account-user-avatar-content">
        <div
          className="account-user-avatar-content-avatar"
          onClick={handleClick}
        >
          {value ? (
            <>
              <img
                alt="avatar"
                src={value}
                className="account-user-avatar-content-avatar-image"
              />
              <strong className="account-user-avatar-content-avatar-span">
                {texts.edit}
                <Icon name="edit" />
              </strong>
            </>
          ) : (
            <>
              <Icon
                name="user"
                size="big"
                className="account-user-avatar-content-avatar-icon"
              />
              <strong className="account-user-avatar-content-avatar-span">
                {texts.add}
                <Icon name="plus" />
              </strong>
            </>
          )}
        </div>
      </div>
      <input
        className="account-user-avatar-input"
        type="file"
        accept=".jpg, .jpeg, .png"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </div>
  );
};

const FormText = ({
  fieldData: {
    name,
    value,
    texts,
    onChange,
    type,
    is_disabled,
    is_required,
    default_value,
  },
}) => {
  return (
    <>
      <Label>{`${texts.label}${is_required ? "*" : ""}`}</Label>
      <Input
        required={is_required}
        id={name}
        type={type ? type : "text"}
        name={name}
        disabled={is_disabled}
        value={value || default_value}
        onChange={onChange}
        readonly
      />
    </>
  );
};

FormText.propTypes = {
  fieldData: propTypes.object,
};

const FormCheckbox = ({ fieldData: { name, value, texts, onChange } }) => {
  const parsedValue = !!+value;
  return (
    <>
      <Label>{texts.label}</Label>
      <Checkbox
        id={name}
        name={name}
        onChange={onChange}
        checked={parsedValue}
      />
    </>
  );
};

FormCheckbox.propTypes = {
  fieldData: propTypes.object,
};

const FormTextarea = ({
  fieldData: { name, value, texts, onChange, is_required },
}) => {
  return (
    <>
      <Label>{`${texts.label}${is_required ? "*" : ""}`}</Label>
      <TextArea
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        required={is_required}
      />
    </>
  );
};

FormTextarea.propTypes = {
  fieldData: propTypes.object,
};

const FormSelect = ({
  fieldData: { name, value, texts, onChange, multiple, searchable, options },
  provinceCityOptions,
}) => {
  const defaultValue = value ? value : multiple ? [] : undefined;
  const trueOptions = _.flow(
    _.cond([
      [
        (n) => _.isEqual("province")(n),
        () =>
          _.map(({ cpro, provincia }) => ({
            key: cpro + provincia,
            value: cpro,
            text: provincia,
          }))(provinceCityOptions),
      ],
      [
        (n) => _.isEqual("city")(n),
        () =>
          _.map(({ id, cmun, municipio }) => ({
            key: cmun + municipio,
            value: id,
            text: municipio,
          }))(provinceCityOptions),
      ],
      [
        _.stubTrue,
        () =>
          _.map((option) => ({
            key: option,
            value: option,
            text: texts.options[option],
          }))(options),
      ],
    ])
  )(name);

  return (
    <>
      <Label>{texts.label}</Label>
      <Dropdown
        id={name}
        name={name}
        deburr
        fluid
        placeholder={Texts.account.channel.select}
        search={searchable}
        selection
        multiple={multiple}
        clearable={multiple}
        options={trueOptions}
        disabled={_.isEmpty(trueOptions)}
        value={defaultValue}
        onChange={(event, data) => onChange(event, data)}
      />
    </>
  );
};

FormSelect.propTypes = {
  fieldData: propTypes.object,
};

const FormPicture = ({ fieldData: { name, value, ratio, onChange } }) => {
  const fileInputRef = useRef(null);
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        const base64Image = await convertBase64(selectedFile);
        onChange(null, { name, value: base64Image });
      } else {
        alert(Texts.account.channel.form.picture.error);
      }
    }
  };
  return (
    <div className={`account-channel-picture`}>
      <div className="account-channel-picture-content">
        <Label>{Texts.account.channel.form.picture[name].label}</Label>
        <div
          className={`account-channel-picture-content-picture ${ratio} ${
            !value ? "add" : ""
          }`}
          onClick={handleClick}
        >
          {value ? (
            <>
              <img
                alt=""
                src={value}
                className="account-channel-picture-content-picture-image"
              />
              <strong className="account-channel-picture-content-picture-span">
                {Texts.account.channel.form.picture.edit}
                <Icon name="edit" />
              </strong>
            </>
          ) : (
            <>
              <Icon
                name="image"
                size="big"
                className="account-channel-picture-content-picture-icon"
              />
              <strong className="account-channel-picture-content-picture-span">
                {Texts.account.channel.form.picture.add}
                <Icon name="plus" />
              </strong>
            </>
          )}
        </div>
      </div>
      <input
        className="account-channel-picture-input"
        type="file"
        accept=".jpg, .jpeg, .png"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </div>
  );
};

const FormPictures = ({
  fieldData: { value, texts, onChange },
  formValues,
}) => {
  const imagesLimit = 4;
  const fileInputRefs = {
    picture0: useRef(null),
    picture1: useRef(null),
    picture2: useRef(null),
    picture3: useRef(null),
  };
  const images = _.reduce(
    (acc, i) =>
      _.set(
        "picture" + i,
        _.flow(_.filter(_.includes("picture_" + i + "_")), _.first)(value)
      )(acc),
    {},
    _.times(_.identity, imagesLimit)
  );

  const handleClick = (key) => {
    if (fileInputRefs[key]?.current) {
      fileInputRefs[key]?.current.click();
    }
  };

  const handleFileChange = async (e, key) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        const base64Image = await convertBase64(selectedFile);
        onChange(null, {
          name: key,
          value: base64Image,
        });
      } else {
        alert(Texts.account.channel.form.picture.error);
      }
    }
  };

  const picRemove = (key) => {
    onChange(null, {
      name: key,
      value: "removed",
    });
  };
  const imageSet = (image, key) => {
    return (
      <div className="account-channel-gallery-content-picture edit" key={key}>
        <img
          alt="avatar"
          src={image}
          className="account-channel-gallery-content-picture-image"
          // onClick={() => handleClick(key)}
        />
        <ButtonGroup className="account-channel-gallery-content-picture-actions">
          <Popup
            content={texts.edit}
            trigger={
              <Button primary icon="edit" onClick={() => handleClick(key)} />
            }
          />
          <Popup
            content={texts.remove}
            trigger={
              <Button primary icon="trash" onClick={() => picRemove(key)} />
            }
          />
        </ButtonGroup>
        <input
          className="account-channel-gallery-input"
          id={`account-channel-gallery-input-${key}`}
          type="file"
          accept=".jpg, .jpeg, .png"
          ref={fileInputRefs[key]}
          onChange={(e) => handleFileChange(e, key)}
        />
      </div>
    );
  };

  const imageUnset = (key) => (
    <div
      className="account-channel-gallery-content-picture add"
      onClick={() => handleClick(key)}
      key={key}
    >
      <Icon
        name="images"
        size="big"
        className="account-channel-gallery-content-picture-icon"
      />
      <strong className="account-channel-gallery-content-picture-span">
        {Texts.account.channel.form.picture.add}
        <Icon name="plus" />
      </strong>
      <input
        className="account-channel-gallery-input"
        id={`account-channel-gallery-input-${key}`}
        type="file"
        accept=".jpg, .jpeg, .png"
        ref={fileInputRefs[key]}
        onChange={(e) => handleFileChange(e, key)}
      />
    </div>
  );

  const imagesBlocks = _.flow(
    _.toPairs,
    _.map(([key, value]) =>
      _.cond([
        [_.isEmpty, () => imageUnset(key)],
        [_.isEqual("removed"), () => imageUnset(key)],
        [_.stubTrue, (value) => imageSet(value, key)],
      ])(formValues[key] || value)
    )
  )(images);

  return (
    <div className="account-channel-gallery">
      <Label>{texts.label}</Label>
      <div className="account-channel-gallery-content">
        {_.map((imageBlock) => imageBlock)(imagesBlocks)}
      </div>
    </div>
  );
};

const FormVideos = ({ fieldData: { name, value, texts, onChange } }) => {
  const videos = value ? value : [];
  const fileInputRef = useRef(null);
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;
      if (
        fileType === "video/mp4" ||
        fileType === "video/mpeg" ||
        fileType === "video/avi"
      ) {
        onChange(null, {
          name,
          value: _.uniq([...videos, selectedFile]),
        });
      } else {
        alert(Texts.account.channel.form.videos.error);
      }
    }
  };
  var count = 0;
  return (
    <div className="account-channel-gallery">
      <Label>{texts.label}</Label>
      <div className="account-channel-gallery-content">
        {_.map((video) => {
          return (
            <div
              key={count++}
              className="account-channel-gallery-content-picture"
              // onClick={handleClickDelete}
            >
              <video style={{ maxWidth: 250, maxHeight: 360 }} controls>
                {_.cond([
                  [
                    _.isEqual("object"),
                    () => (
                      <source
                        src={URL.createObjectURL(video)}
                        type="video/mp4"
                      />
                    ),
                  ],
                  [
                    _.isEqual("string"),
                    () => <source src={video} type="video/mp4" />,
                  ],
                ])(typeof video)}
                Your browser does not support the video tag.
              </video>
              <strong
                className="account-channel-gallery-content-picture-span"
                onClick={() => handleClick()}
              >
                {Texts.account.channel.form.picture.edit}
                <Icon name="edit" />
              </strong>
            </div>
          );
        })(videos)}

        {videos.length < 2 ? (
          <div
            className="account-channel-gallery-content-picture add"
            onClick={handleClick}
          >
            <Icon
              name="video"
              size="big"
              className="account-channel-gallery-content-picture-icon"
            />
            <strong className="account-channel-gallery-content-picture-span">
              {Texts.account.channel.form.picture.add}
              <Icon name="plus" />
            </strong>
          </div>
        ) : null}
      </div>
      <input
        className="account-channel-gallery-input"
        type="file"
        accept=".avi, .mpeg, .mp4"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </div>
  );
};

const FormAudios = ({ fieldData: { name, value, texts, onChange } }) => {
  const audios = value ? value : [];
  const fileInputRef = useRef(null);
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;
      if (
        fileType === "audio/mpeg" ||
        fileType === "audio/wav" ||
        fileType === "audio/ogg" ||
        fileType === "audio/x-m4a"
      ) {
        onChange(null, {
          name,
          value: _.uniq([...audios, selectedFile]),
        });
      } else {
        alert(Texts.account.channel.form.audios.error);
      }
    }
  };
  var count = 0;
  return (
    <div className="account-channel-gallery">
      <Label>{texts.label}</Label>
      <div className="account-channel-gallery-content">
        {_.map((audio) => {
          return (
            <div
              key={count++}
              className="account-channel-gallery-content-picture"
              // onClick={handleClickDelete}
            >
              <audio controls>
                <source src={URL.createObjectURL(audio)} type="audio/mp3" />
                Your browser does not support the audio tag.
              </audio>
              <strong
                className="account-channel-gallery-content-picture-span"
                onClick={() => handleClick()}
              >
                {Texts.account.channel.form.picture.edit}
                <Icon name="edit" />
              </strong>
            </div>
          );
        })(audios)}

        {audios.length < 2 ? (
          <div
            className="account-channel-gallery-content-picture add"
            onClick={handleClick}
          >
            <Icon
              name="headphones"
              size="big"
              className="account-channel-gallery-content-picture-icon"
            />
            <strong className="account-channel-gallery-content-picture-span">
              {Texts.account.channel.form.picture.add}
              <Icon name="plus" />
            </strong>
          </div>
        ) : null}
      </div>
      <input
        className="account-channel-gallery-input"
        type="file"
        accept=".mp3, .wav, .ogg"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </div>
  );
};

const FormNewPassword = ({
  fieldData: { name, value, texts, onChange, type },
}) => {
  return (
    <>
      <Label>{texts.label}</Label>
      <Input
        id={name}
        type="password"
        name={name}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

const FormHidden = ({
  fieldData: { name, value, texts, onChange, type, is_disabled },
}) => {
  return (
    <>
      <Input
        id={name}
        type="hidden"
        name={name}
        disabled={is_disabled}
        value={value}
      />
    </>
  );
};

FormHidden.propTypes = {
  fieldData: propTypes.object,
};

const FormSubmit = ({ fieldData: { name, texts } }) => {
  return (
    <>
      <Button primary id={name} type="submit" name={name}>
        {texts.label}
      </Button>
    </>
  );
};

FormSubmit.propTypes = {
  fieldData: propTypes.object,
};

const AccountForm = ({
  name,
  formFields,
  values,
  texts,
  onSubmit,
  provincesCities,
}) => {
  const [formValues, setFormValues] = useState(values);

  useEffect(() => {
    setFormValues(values);
  }, [values]);

  const handleChange = (data) => {
    const { name, value, checked } = data;

    if (name === "province") {
      const { municipios: cities } = provincesCities;
      const filteredCitiesIds = _.flow(
        _.filter(
          ({ id, cpro }) =>
            _.includes(id)(formValues.city) && _.includes(cpro)(value)
        ),
        _.map(_.get("id"))
      )(cities);

      setFormValues((prevValues) => ({
        ...prevValues,
        province: value,
        city: filteredCitiesIds,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value || checked,
      }));
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    onSubmit(formValues);
  };

  return (
    <Form name={`${name}-form`} onSubmit={handleOnSubmit}>
      {formFields.map((field, k) => {
        const provinceCityOptions = _.cond([
          [_.isEqual("province"), () => provincesCities?.provincia],
          [
            _.isEqual("city"),
            () =>
              _.filter(({ cpro }) => _.includes(cpro)(formValues?.province))(
                provincesCities?.municipios || []
              ),
          ],
          [_.stubTrue, () => field.options],
        ])(field.name);

        const fieldData = {
          ...field,
          texts: texts.form[field.name],
          value: formValues[field.name],
          onChange: (_e, data) => handleChange(data),
        };
        let element = null;
        switch (field.type) {
          case "avatar":
            element = (
              <Form.Field key={k}>
                <FormAvatar fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "text":
          case "number":
          case "tel":
          case "email":
            element = (
              <Form.Field key={k}>
                <FormText fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "checkbox":
            element = (
              <Form.Field key={k}>
                <FormCheckbox fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "textarea":
            element = (
              <Form.Field key={k}>
                <FormTextarea fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "select":
          case "tags":
          case "province":
          case "city":
            element = (
              <Form.Field key={k}>
                <FormSelect
                  fieldData={fieldData}
                  provinceCityOptions={provinceCityOptions}
                  selectedProvince={formValues}
                />
              </Form.Field>
            );
            break;

          case "picture":
            element = (
              <Form.Field key={k}>
                <FormPicture fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "pictures":
            element = (
              <Form.Field key={k}>
                <FormPictures fieldData={fieldData} formValues={formValues} />
              </Form.Field>
            );
            break;

          case "videos":
            element = (
              <Form.Field key={k}>
                <FormVideos fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "audios":
            element = (
              <Form.Field key={k}>
                <FormAudios fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "hidden":
            element = <FormHidden key={k} fieldData={fieldData} />;
            break;

          case "submit":
            element = (
              <Form.Field key={k}>
                <FormSubmit fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "new-password":
            element = (
              <Form.Field key={k}>
                <FormNewPassword fieldData={fieldData} />
              </Form.Field>
            );
            break;

          default:
            console.error(`No Field Format for ${field.type}`);
            element = null;
            break;
        }
        return element;
      })}
    </Form>
  );
};

AccountForm.propTypes = {
  name: propTypes.string,
  formFields: propTypes.array,
  values: propTypes.object,
  onSubmit: propTypes.func,
  labels: propTypes.object,
  provincesCities: propTypes.object,
};

export default AccountForm;
