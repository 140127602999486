import useSWR from "swr";
import fetch from "unfetch";
import { API_CHANNELS, API_CHANNELS_CITIES_FILTERS } from "./endpoints";

export const useChannels = (filters) => {
  let url = API_CHANNELS;
  if (filters) {
    const queryParams = new URLSearchParams(filters).toString();
    url += `?${queryParams}`;
  }
  const fetcher = (url) => fetch(url).then((r) => r.json());
  const { data, error, isLoading } = useSWR(url, fetcher);

  return { channels: data, error, isLoading };
};

export const useChannelscitiesFilters = () => {
  let url = API_CHANNELS_CITIES_FILTERS;
  const fetcher = (url) => fetch(url).then((r) => r.json());
  const { data, error, isLoading } = useSWR(url, fetcher);

  return { cities: data, error, isLoading };
};
