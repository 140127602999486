import _ from "lodash/fp";
import React from "react";
import propTypes from "prop-types";
import { map } from "lodash/fp";
import ChannelCard from "./ChannelCard";
import ChannelsFilter from "./ChannelsFilter";

const ChannelCardsWrapper = ({ channels, loading, filterChannels }) => {
  return (
    <>
      <ChannelsFilter
        channels={channels}
        filterChannels={filterChannels}
      ></ChannelsFilter>
      <section className="channel-cards-wrapper">
        {map.convert({ cap: false })((channel, k) => (
          <ChannelCard
            slug={channel.slug}
            display_name={channel.display_name}
            thumbnail={channel.thumbnail}
            description={channel.descripcion_corta}
            city={channel.ciudad}
            zone={channel.zona}
            premium={_.isEqual("1")(channel.premium)}
            verified={_.isEqual("1")(channel.verified)}
            key={k}
          />
        ))(channels)}
      </section>
    </>
  );
};

ChannelCardsWrapper.propTypes = {
  channels: propTypes.array,
  filterChannels: propTypes.func,
};

export default ChannelCardsWrapper;
