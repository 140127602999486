import React, { useState } from "react";
import { PathRoutes, Texts } from "../../data/Data";
import { usePasswordRecovery } from "../../data/api/useSession";
import SessionPasswordRecoveryForm from "../../data/forms/SessionPasswordRecovery";
import SessionForm from "../../components/SessionForm";
import MessageModal from "../../components/MessageModal";

export const SessionPasswordRecovery = ({ updateUserData, loginType }) => {
  const { trigger } = usePasswordRecovery();
  const [open, setOpen] = useState(false);

  const handleOnSubmit = (values) => {
    trigger(values).then((response) => {
      setOpen(true);
    });
  };

  return (
    <main className="session-login">
      <div className="session-login-heading">
        <h2 className="session-login-heading-title">
          {Texts.session.passwordRecovery.title}
        </h2>
      </div>
      <div className="session-login-content">
        <div className="session-login-content-block">
          <div className="session-login-content-block-login">
            <h5>{Texts.session.passwordRecovery.recovery.title}</h5>
            <SessionForm
              name="session-password-recovery"
              formFields={SessionPasswordRecoveryForm}
              values={{}}
              onSubmit={handleOnSubmit}
              texts={Texts.session.passwordRecovery.recovery}
            />
          </div>
        </div>
      </div>
      <MessageModal
        open={open}
        texts={Texts.session.passwordRecovery.messages.success}
        action={() =>
          (window.location.href = PathRoutes.PATH_SESSION_LOGIN_USER)
        }
        onClose={() =>
          (window.location.href = PathRoutes.PATH_SESSION_LOGIN_USER)
        }
      />
    </main>
  );
};

export default SessionPasswordRecovery;
