import React from "react";
import { Imgs } from "../data/Data";

export const Logo = () => {
  return (
    <a className="logo" href="/">
      <img className="logo-img" src={Imgs.header.logo} alt="Logo" />
    </a>
  );
};

export default Logo;
