import React, { useContext, useState } from "react";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import { Container, Message, Segment } from "semantic-ui-react";
import { PathRoutes, Texts } from "../../data/Data";
import {
  useChannelNew,
  useChannelInmutable,
  useChannelUpdate,
  useChannelMedia,
} from "../../data/api/useChannel";
import AccountForm from "../../components/AccountForm";
import AccountChannelForm from "../../data/forms/AccountChannelForm";
import AccountChannelMediaForm from "../../data/forms/AccountChannelMediaForm";
import _ from "lodash/fp";
import { API_PRE } from "../../data/api/endpoints";
import MessageModal from "../../components/MessageModal";
import { sessionContext } from "../../App";
import { useProvincesCities } from "../../data/api/useProvincesCities";

const AccountChannel = ({ channel = {}, trigger }) => {
  const { id: id_anunciante } = useContext(sessionContext);
  const [open, setOpen] = useState(false);
  const [openMedia, setOpenMedia] = useState(false);
  const [channelFormValues, setChannelFormValues] = useState(null);
  const [slug, setSlug] = useState(channel.channel);
  const { trigger: triggerMedia } = useChannelMedia(channel.channel);
  const { data: provincesCities } = useProvincesCities();

  const handleOnSubmit = (values) => {
    setChannelFormValues(values);
    setSlug(values.channel);
    trigger({ id_anunciante, ...values }).then((response) => {
      setOpen(true);
    });
  };

  const mediaHandleOnSubmit = (values) => {
    const formValues = { id_anunciante, ...values };
    const { videos, audios, ...data } = formValues;
    const formData = new FormData();
    for (var dataKey in data) {
      formData.append(dataKey, data[dataKey]);
    }

    for (var videosKey in videos) {
      formData.append("video" + videosKey, videos[videosKey]);
    }
    for (var audioKey in audios) {
      formData.append("audio" + audioKey, audios[audioKey]);
    }
    triggerMedia(formData).then((response) => {
      setOpenMedia(true);
    });
  };

  const parsedChannel = _.flow(
    ({
      pictures,
      channelThumbnail,
      channelTopVertical,
      channelTopHorizontal,
      social_network,
      interests,
      vids,
    }) => {
      const parsedPictures = _.flow(
        _.split(";"),
        _.map((value) => _.trim(value)),
        _.filter((value) => !_.isEmpty(value)),
        _.map((value) => API_PRE + value)
      )(pictures);

      const parsedFeedPictures = _.flow(
        _.mapValues((value) => _.trim(value)),
        _.pickBy((value) => !_.isEmpty(value)),
        _.mapValues((value) => API_PRE + value)
      )({ channelThumbnail, channelTopVertical, channelTopHorizontal });

      const parsedSocial = _.flow(
        _.split(";"),
        _.map((value) => _.trim(value)),
        _.reduce(
          (acc, value) => {
            const key = _.flow(
              _.cond([
                [_.includes("instagram"), _.constant("social_instagram")],
                [_.includes("x"), _.constant("social_x")],
                [_.includes("twitter"), _.constant("social_x")],
                [_.includes("onlyfans"), _.constant("social_onlyfans")],
                [_.stubTrue, _.constant("social_other")],
              ])
            )(value);
            return { ...acc, [key]: value };
          },
          {
            social_x: "",
            social_instagram: "",
            social_onlyfans: "",
            social_other: "",
          }
        )
      )(social_network);

      const parsedInterests = _.flow(
        _.split(";"),
        _.map((value) => _.trim(value)),
        _.filter((value) => !_.isEmpty(value))
      )(interests);

      const parsedVideos = _.flow(
        _.reject((v) => _.includes(v)([".", ".."])),
        _.reject((v) => _.startsWith("path:")(v)),
        _.map(
          (v) =>
            `https://2024.vivalavidaloca.com/vlvl/api/media/channels/${channel.channel}/vids/${v}`
        )
      )(vids);

      return {
        ...channel,
        ...parsedFeedPictures,
        pictures: parsedPictures,
        ...parsedSocial,
        interests: parsedInterests,
        videos: parsedVideos,
      };
    }
  )(channel);

  return (
    <Container className="account-channel">
      <h2>
        {channel ? Texts.account.channel.edit : Texts.account.channel.new}
      </h2>
      <Segment className="account-channel-form">
        <Message negative>
          <p>{parse(Texts.account.channel.disclaimer)}</p>
        </Message>
        <AccountForm
          name="account-channel"
          formFields={AccountChannelForm}
          values={channelFormValues || parsedChannel}
          onSubmit={handleOnSubmit}
          texts={Texts.account.channel}
          provincesCities={provincesCities}
        />
        {channel.channel || true ? (
          <AccountForm
            name="account-channel-media"
            formFields={AccountChannelMediaForm}
            values={channelFormValues || parsedChannel}
            onSubmit={mediaHandleOnSubmit}
            texts={Texts.account.channel}
          />
        ) : null}
      </Segment>
      <MessageModal
        open={open}
        texts={
          _.isEmpty(channel)
            ? Texts.account.channel.messages.success.edit
            : Texts.account.channel.messages.success.new
        }
        action={() =>
          (document.location.href = `${PathRoutes.PATH_ACCOUNT_MANAGER}/${slug}`)
        }
        onClose={() =>
          (document.location.href = `${PathRoutes.PATH_ACCOUNT_MANAGER}/${slug}`)
        }
      />
      <MessageModal
        open={openMedia}
        texts={Texts.account.channel.messages.success.media}
        action={() => setOpenMedia(false)}
        onClose={() => setOpenMedia(false)}
      />
    </Container>
  );
};

AccountChannel.prototype = {};

export const AccountChannelNew = () => {
  const { trigger } = useChannelNew();
  return <AccountChannel trigger={trigger} />;
};

export const AccountChannelEdit = () => {
  const { id } = useParams();

  const { channel, isLoading } = useChannelInmutable(id, true);
  if (_.isEmpty(channel) && !isLoading) {
    document.location.href = PathRoutes.PATH_ACCOUNT_MANAGER;
  }

  const { trigger } = useChannelUpdate(id);

  return !isLoading && !_.isEmpty(channel) ? (
    <AccountChannel channel={channel} trigger={trigger} />
  ) : null;
};
