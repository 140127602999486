import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import fetch from "unfetch";
import { API_USER, API_USER_ACTION, API_USER_EDIT } from "./endpoints";
import _ from "lodash/fp";

export const useUser = (id) => {
  const url = API_USER.replace(":id", id);
  const fetcher = (url) => fetch(url).then((r) => r.json());
  const { data, error, isLoading, mutate } = useSWR(url, fetcher);

  // BLOCK Temporarily for translations
  const empty_user = {
    id: null,
    avatar: "",
    nombre: "",
    apellidos: "",
    alias: "",
    email: "",
    telefono1: "",
    telefono2: "",
    whatsapp: "",
    acceso_salto: "",
  };
  // const empty_user = {
  //   id: 999,
  //   nombre: "Javier",
  //   apellidos: "Santos",
  //   alias: "Jantos",
  //   email: "mail@mail.com",
  //   telefono1: "555444666",
  //   telefono2: "666444888",
  //   whatsapp: "666444888",
  //   acceso_salto: "1",
  // };

  const translation = {
    id: "id",
    avatar: "avatar",
    nombre: "name",
    apellidos: "surname",
    alias: "alias",
    email: "email",
    telefono1: "phone",
    telefono2: "phone2",
    whatsapp: "whatsapp",
    acceso_salto: "salto",
  };

  // END Temporarily for translations

  const user = _.flow(
    _.mapKeys((key) => translation[key]), // Temporarily for translations
    _.omit(["undefined"])
  )(data?.[0] || empty_user);
  return { user: user, error, isLoading, mutate };
};

export const useUserUpdate = (id) => {
  const url = API_USER_EDIT.replace(":id", id);
  const fetcher = (url, payload) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((r) => {
      return r.json();
    });
  };
  return useSWRMutation(url, (url, { arg }) => {
    return fetcher(url, arg);
  });
};

export const useUserAction = () => {
  const url = API_USER_ACTION;
  const fetcher = (url, payload) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((r) => {
      return r.json();
    });
  };
  return useSWRMutation(url, (url, { arg }) => {
    return fetcher(url, arg);
  });
};
