export const LegalPrivacyPolicy = {
  title: "POLÍTICA DE PRIVACIDAD DE VIPTOENJOY.COM",
  content: `
  <div class="entry-content wp-block-post-content is-layout-constrained wp-block-post-content-is-layout-constrained">
  <p>Nuestra Política de Privacidad no solo te explica la forma en la que tratamos tus datos personales sino también te informa de todos los derechos que te asisten, como consecuencia de la entrada en vigor del&nbsp;<em>Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos</em>&nbsp;(en adelante, RGPD) y lo establecido en la normativa española vigente en esta materia.</p>
  
  <p><strong>1. ¿Quién es el responsable del tratamiento?</strong></p>
  
  <p>Viptoenjoy, S.L. (en adelante, «ViptoEnjoy.com»), con la dirección ViptoEnjoy, c/Divina providencia 08800 Vilanova i la Geltrú, Barcelona y con Número de Identificación Fiscal B66684143 e inscrita en el Registro Mercantil de Barcelona, Tomo 45200, Folio 99, Inscripción 1, Hoja B 480072.</p>
  
  <p><strong>2.</strong>&nbsp;<strong>¿Qué es el Delegado de Protección de Datos (“DPO”) y cómo puedo contactar?</strong></p>
  
  <p>Viptoenjoy, como garantía de su buena práctica empresarial y comprometida en la protección de datos de sus usuarios, cuenta con un DPO, encargado de garantizar la correcta aplicación de su política de protección de datos. Cualquier consulta o petición que puedas tener podrás contactar con el DPO a través del correo electrónico&nbsp;<a href="mailto:info@viptoenjoy.com">info@viptoenjoy.com</a>&nbsp;.</p>
  
  <p><strong>3.</strong>&nbsp;<strong>¿Qué datos personales tratamos?</strong></p>
  
  <p>Viptoenjoy recaba datos de carácter personal suministrados por los usuarios de la plataforma publicitaria de forma libre y voluntaria, al objeto de que se puedan prestar los servicios publicitarios contratados u otros servicios informativos sobre nuevos servicios publicitarios.</p>
  
  <p>Viptoenjoy es responsable del tratamiento de datos proporcionados por los usuarios de forma libre e inequívoca. Sin embargo, los datos de carácter personal que los usuarios y profesionales decidan expresa e inequívocamente publicar en el espacio publicitario contratado en Viptoenjoy no es responsabilidad del propietario de la plataforma.</p>
  
  <p>El usuario de Viptoenjoy es responsable de la veracidad de los datos de carácter personal. Aun así, Viptoenjoy dispone de medios para comprobar la mayoría de edad de usuarios y profesionales de la plataforma, adjuntando copia del NIF, pasaporte u otros documentos identificativos, garantizando la confidencialidad de sus datos.</p>
  
  <p>Las personas que contratan servicios publicitarios son mayores de edad y han otorgado consentimiento libre y expreso para formalizar un contrato de servicios publicitarios y de intermediación, dentro de la plataforma publicitaria, a través de la creación de perfiles públicos. Por este motivo, los contenidos que incluyan en sus espacios publicitarios, incluyendo la publicación de datos de carácter personal, no será responsabilidad de Viptoenjoy.</p>
  
  <p>&nbsp;Viptoenjoy ha recabado tus datos en las siguientes ocasiones:</p>
  
  <p>(i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cumplimentando formularios para obtener información sobre nuestros servicios.</p>
  
  <p>(ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cumplimentando formularios para formalizar una relación contractual con nosotros consistente en la prestación de servicios publicitarios.</p>
  
  <p><strong>4.</strong>&nbsp;<strong>¿Para qué usamos tus datos?</strong></p>
  
  <p>En Viptoenjoy tratamos tus datos personales con el máximo respeto, transparencia y con la información adecuada y pertinente para las siguientes finalidades:</p>
  
  <p><strong>&nbsp;i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>Cumplir con las obligaciones contractuales asumidas contigo:</strong></p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; a)&nbsp;&nbsp;&nbsp;&nbsp; Contactarte ante cualquier incidencia, como consecuencia de la contratación de prestación de servicios publicitarios.</p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; b)&nbsp;&nbsp;&nbsp;&nbsp; Contactarte ante cualquier gestión de carácter extracontractual.</p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; c)&nbsp;&nbsp;&nbsp;&nbsp; Alta en la plataforma como usuario registrado.</p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; d)&nbsp;&nbsp;&nbsp;&nbsp; Medir tu satisfacción con nuestros servicios.</p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; e)&nbsp;&nbsp;&nbsp;&nbsp; Informarte sobre los nuevos servicios publicitarios que se adecúen a tus preferencias.</p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; f)&nbsp;&nbsp;&nbsp;&nbsp; Gestión de cualquier tipo de incidencias.</p>
  
  <p><strong>&nbsp;ii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>Cumplir con las obligaciones previstas en la legislación aplicable.</strong></p>
  
  <p><strong>&nbsp;iii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Llevar a cabo iniciativas promocionales, publicitarias o de servicios propios de Viptoenjoy:</strong></p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; a.&nbsp;&nbsp; Si no formalizas ninguna relación contractual directamente, conservaremos tus datos previamente facilitados por ti, siempre que nos hayas proporcionado tu consentimiento explícito para ello, por ejemplo, altas en Newsletters, para informarte de nuestras nuevas ofertas formativas.</p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; b.&nbsp;&nbsp; Administrar tu participación encuestas de satisfacción de nuestros servicios, siempre que nos otorgues tu consentimiento previamente.</p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; c.&nbsp;&nbsp; Informarte de ofertas y promociones.</p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; d.&nbsp;&nbsp; Contactarte ante cualquier incidencia por una mala utilización de la plataforma.</p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; e.&nbsp;&nbsp; Informarte de la disponibilidad de un curso sobre el que previamente nos hayas solicitado información. y/o reservado.</p>
  
  <ol>
  <li><strong>iv.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>Mantenimiento de listas de supresión actualizadas para evitar ser contactado si lo solicitas.</strong></li>
  </ol>
  
  <ol>
  <li><strong>v.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>Realización de estadísticas (</strong>siempre que nos otorgues de antemano tu consentimiento explícito)<strong>, que incluye:</strong></li>
  </ol>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; a.&nbsp;&nbsp; Elaboración de informes y estadísticas.</p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; b.&nbsp;&nbsp; Conocer el impacto de nuestras campañas en los usuarios.</p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; c.&nbsp;&nbsp; Mejorar nuestra experiencia con el usuario.</p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; d.&nbsp;&nbsp; Mejorar nuestra oferta de servicios atendiendo a tus sugerencias.</p>
  
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; e.&nbsp;&nbsp; Conocer como encontró nuestra plataforma /app.</p>
  
  <p><strong>5.</strong>&nbsp;<strong>¿Cuál es la legitimación para el tratamiento de tus datos?</strong></p>
  
  <p>Nuestra legitimación para el tratamiento de tus datos se fundamenta en el hecho de que nos has facilitado tus datos (i) al cumplimentar nuestro formulario para obtener información sobre nuestros servicios; (ii) al cumplimentar formularios para formalizar una relación contractual de prestación de servicios publicitarios con nosotros; (iii) al cumplimentar otros formularios que pudiéramos poner a tu disposición. A partir de ese momento, se ha iniciado una relación precontractual, cuasi contractual, contractual y/o cuasicontractual, al ceder tus datos para formar parte de nuestras Bases de Datos.</p>
  
  <p>El envío de comunicaciones informativas y comerciales está basado no solo en nuestro interés legítimo consistente en la posibilidad de promocionar nuestros servicios publicitarios entre nuestros clientes, sino en el consentimiento explícito, a través de los formularios que –en su día- has cumplimentado (i) bien para interesarte sobre los servicios publicitarios de Viptoenjoy, (ii) bien para contratar un servicio publicitario dentro de la plataforma Viptoenjoy, (iii) bien para interesarte en alguno de nuestros nuevos servicios o en alguna de nuestras promociones.</p>
  
  <p>Si no deseas recibir nuestras comunicaciones, puedes oponerte en cualquier momento a través del correo electrónico&nbsp;<a href="mailto:info@viptoenjoy.com">info@viptoenjoy.com</a>&nbsp;o a través del procedimiento de baja automática habilitado en nuestras comunicaciones electrónicas.</p>
  
  <p>Nos basamos, igualmente, en nuestro interés legítimo para la realización de estadísticas que nos ayuden a comprender mejor tus necesidades y mejorar tu experiencia en Viptoenjoy. Deseamos mejorar, de esta forma, nuestros servicios, a través de nuestra plataforma o app. Además, estamos interesados legítimamente en el correcto funcionamiento de nuestra plataforma/app a través de cookies técnicas y funcionales, así como en el mantenimiento de nuestras herramientas seguras, para garantizar su buen funcionamiento y hacerte una navegación mucho más práctica y eficaz.</p>
  
  <p><strong>6.</strong>&nbsp;<strong>¿Cuáles son tus derechos cuando nos comunicas tus datos?</strong></p>
  
  <p>Como usuario nuestros servicios y titular de tus datos podrás ejercitar en cualquier momento los siguientes derechos:</p>
  
  <ul>
  <li>Derecho de acceso a tus datos personales para saber conocer su tratamiento.</li>
  
  <li>Derecho de rectificación para que puedas modificar en cualquier momento actualizar o modificar un dato personal.</li>
  
  <li>Derecho de supresión de tus datos personales, cuando ya no sean necesarios para los fines que fueron recogidos.</li>
  
  <li>Derecho de oposición, es decir, solicitar que no se traten tus datos de carácter personal.</li>
  
  <li>Derecho a retirar tu consentimiento en cualquier momento y sin justificar la causa de la revocación.</li>
  
  <li>Derecho a solicitar la limitación del tratamiento de tus datos personales:</li>
  </ul>
  
  <ul>
  <li>En el caso de que se esté comprobando la exactitud de los mismos.</li>
  
  <li>En el caso de un tratamiento ilícito, lo que determinaría el borrado de tus datos, pero no has ejercido tu derecho de oposición.&nbsp;</li>
  
  <li>En el caso de que Viptoenjoy no requiera del tratamiento de tus datos, pero los necesites para la defensa de reclamaciones.&nbsp;</li>
  
  <li>En el caso de que te hayas opuesto al tratamiento de tus datos para el cumplimiento de una misión de interés público o la satisfacción de un interés legítimo, mientras se verifica si la causa legítima para el tratamiento prevalece o no sobre tu situación personal.</li>
  
  <li>Derecho de portabilidad, de tal forma que podrás recibir los datos personales que nos hayas facilitado, siempre que sea posible, en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro responsable del tratamiento. Para ejercitar estos derechos, podrás ponerte en contacto con nosotros a través del siguiente correo electrónico:&nbsp;<a href="mailto:info@viptoenjoy.com">info@viptoenjoy.com</a>, indicando en tu solicitud tu nombre completo y el derecho que ejercitas, acompañado de una copia de tu DNI o documento equivalente acreditativo de tu identidad.&nbsp;</li>
  </ul>
  
  <ul>
  <li>Derecho a presentar reclamación ante la Agencia Española de Protección de Datos. Aun así, no dudes en ponerte en contacto previamente con nuestro DPO&nbsp;antes de presentar una reclamación ante la autoridad competente y así aclarar cualquier incidencia que se haya podido producir. Disponemos de medidas organizativas y técnicas para proteger tus datos de carácter personal de toda pérdida, mal uso, acceso o divulgación no autorizados, alteración y/o destrucción. Lamentablemente, no es posible garantizar totalmente la seguridad de ningún sistema de transmisión o almacenamiento de datos. Si sospechas que tu interacción con nosotros ya no es segura, avisa de inmediato a nuestro DPD a través de&nbsp;<a href="mailto:info@viptoenjoy.com"></a><a href="mailto:info@viptoenjoy.com">info@viptoenjoy.com</a>.</li>
  </ul>
  
  <p><strong>7. ¿Qué obligaciones se asumen al ser responsable de un tratamiento de datos de carácter personal?</strong></p>
  
  <p>Viptoenjoy se compromete a comunicar por escrito y de forma fehaciente al usuario, cualquier ampliación, modificación o extensión de las finalidades y usos que se vayan a proporcionar al tratamiento automatizado de los datos personales entregados por el usuario.</p>
  
  <p>Viptoenjoy informa de que la entrega de todos los datos requeridos para el registro como usuario o para la creación de un perfil es obligatoria en aquellos casos en donde expresamente se especifique, como por ejemplo la inscripción, inserción o creación de perfiles públicos, pudiendo Viptoenjoy denegar el registro al interesado que no facilite tales datos. En los formularios donde se recaben datos de carácter personal, se señalarán los distintos campos cuya cumplimentación es necesaria y obligatoria para confirmar la suscripción, así como aquellos campos cuya cumplimentación es voluntaria en función de la información que disponga el interesado.&nbsp;</p>
  
  <p><strong>8. ¿Cuál es el tiempo establecido para dar solución al ejercicio de derechos?</strong></p>
  
  <p>Te informaremos sobre las actuaciones derivadas de tu petición en el plazo de un mes que podrá extenderse dos meses más cuando se trate de solicitudes especialmente complejas y te notificaremos dicha ampliación dentro del primer mes.</p>
  
  <p>En aquellos casos que no atendamos tu solicitud, te informaremos de ello, motivando su negativa dentro del plazo de un mes desde su presentación.</p>
  
  <p><strong>9. ¿Qué ocurre si no facilito mis datos de carácter personal?</strong></p>
  
  <p>Los datos de carácter obligatorio se distinguirán en los formularios de recogida de información. Si decides no proporcionarnos alguno de esos datos considerados obligatorios no podremos cumplir con la finalidad prevista y no se realizará el tratamiento de datos ni la prestación del servicio correspondiente.</p>
  
  <p>En caso de elaboración de un “<em>perfil público</em>” en base a la información facilitada, no se tomarán decisiones automatizadas con efectos jurídicos para el usuario.</p>
  
  <p>El/la usuari@ / profesional garantiza que los Datos Personales que nos facilita son veraces y correctos, y será responsable de comunicarnos cualquier modificación en los mismos. En el caso de que los datos aportados pertenecieran a un tercero, el/la usuari@ / profesional garantiza que ha informado a dicho tercero de los aspectos contenidos en este documento y obtenido su autorización para facilitar sus datos.</p>
  
  <p><strong>10.</strong>&nbsp;<strong>¿Cuánto tiempo conservaremos tus datos?</strong></p>
  
  <p>Conservaremos tus datos personales durante el tiempo necesario para las finalidades señaladas.</p>
  
  <p>Los datos personales proporcionados se conservarán mientras no solicites su supresión. En todo caso, tus datos personales serán conservados durante el plazo necesario para el cumplimiento efectivo de la finalidad para los que fueron recabados y durante el tiempo establecido por la normativa legal vigente, salvo revocación del consentimiento o ejercicio del derecho a la limitación del tratamiento.</p>
  
  <p>En los casos que no hayas ejercido tu derecho a la cancelación, conservaremos tus datos durante&nbsp;CINCO AÑOS,&nbsp;desde tu última interacción o manifestación de interés en Viptoenjoy o en la disponibilidad de nuestros servicios.&nbsp;</p>
  
  <p>Finalizados estos plazos mantendremos tus datos bloqueados (únicamente a disposición de jueces, Tribunales, Ministerio Fiscal y/o Administraciones Públicas competentes o la gestión de un crédito o deuda) durante los plazos de prescripción legal que fuesen aplicables. Transcurridos los plazos señalados, procederemos a la supresión de tus datos.</p>
  
  <p><strong>11.</strong>&nbsp;<strong>¿A quién cedes tus datos con su publicación en la plataforma&nbsp;</strong><a href="http://www.viptoenjoy.com/"><strong>www.viptoenjoy.com</strong></a><strong>?</strong></p>
  
  <p>El usuario y/o profesional de los datos de carácter personal cede a Viptoenjoy, y por el tiempo de la prestación de los servicios publicitarios, sus datos para su inclusión en otras plataformas webs&nbsp;del titular Viptoenjoy. </p>
  
  <p><strong>12.</strong>&nbsp;<strong>¿Quién más podrá acceder a tus datos?</strong></p>
  
  <p>Nosotros no comercializamos ni ofrecemos a cambio de otras prestaciones tus datos personales a otras empresas.</p>
  
  <p><br>Viptoenjoy no transfiere datos personales fuera del EEE.</p>
  
  <p>Tus datos de carácter personal podrían ser compartidos con los siguientes terceros:</p>
  
  <ul>
  <li>Nuestros proveedores de servicios externos, que enumeramos a continuación: (i) proveedores de servicios de TI, (ii) procesamiento de pagos, (iii) atención al cliente, (iv) moderación de contenidos y textos, y (v) comprobación de la mayoría de edad de usuarios y profesionales. La base jurídica para compartir datos personales con estos destinatarios es que es necesario cumplir con la normativa vigente y la actuación de los proveedores de Viptoenjoy  son necesario para prestar nuestros servicios publicitarios y cumplir con nuestros intereses legítimos.</li>
  </ul>
  
  <ul>
  <li>Nuestros asesores profesionales, que enumeramos a continuación: &nbsp;(i) asesores jurídicos, (ii) servicios bancarios, (iii) auditores, (iv) contables, (v) consultores y (vi) aseguradoras. Nuestros asesores profesionales tratarán los datos personales cuando sea necesario para prestarnos sus servicios. La base jurídica para compartir datos personales con estos destinatarios es asegurarnos del cumplimiento de todas las obligaciones legales en el desarrollo de nuestra actividad empresarial.</li>
  </ul>
  
  <ul>
  <li>Las plataformas virtuales de nuestro grupo para la coordinación y gestión centralizada de nuestro negocio. Estos destinatarios tratarán los datos personales del mismo modo que se establece en la presente Política de Privacidad. La base jurídica en la que nos basamos para compartir datos personales con estos destinatarios es coordinar las operaciones globales de nuestro negocio.</li>
  </ul>
  
  <ul>
  <li>Autoridades administrativas, Tribunales y otros organismos gubernamentales en el caso de que se pidiese la colaboración de Viptoenjoy para que se respeten las garantías, normas y procedimientos previstos en el ordenamiento jurídico para proteger los datos de carácter personal, la libertad de expresión y la libertad de información. La base jurídica en la que nos apoyamos para compartir datos personales con estos destinatarios es que el tratamiento es necesario para cumplir una obligación legal a la que estamos sujetos o cuando ello redunde en interés del público en general, como por ejemplo, para informar sobre contenidos ilegales a dichas autoridades, con el fin de proteger la seguridad de nuestros usuarios y de terceros.</li>
  </ul>
  
  <p>Si desea obtener una copia de las garantías adecuadas para realizar transferencias internacionales, contáctanos a través de&nbsp;<a href="mailto:info@viptoenjoy.com">info@viptoenjoy.com</a>.</p>
  
  <p><strong>13.</strong>&nbsp;<strong>¿La presente política afecta a los servicios de terceros?</strong></p>
  
  <p>Viptoenjoy no es responsable de las políticas de privacidad de terceros a los que se enlace desde nuestra plataforma/app. La inclusión de un enlace de un tercero en nuestra plataforma/app no implica que avalemos su sitio web, su política de privacidad, su tratamiento de datos de carácter personal o servicio enlazado.</p>
  </div>
`,
};

export default LegalPrivacyPolicy;
