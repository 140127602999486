import { PathRoutes } from "./PathRoutes";
import { SocialConnections } from "./SocialConnections";

const social = [
  {
    title: "LinkedIn",
    url: SocialConnections.LINK_LINKEDIN,
  },
  {
    title: "Twitter",
    url: SocialConnections.LINK_TWITTER,
  },
  {
    title: "Youtube",
    url: SocialConnections.LINK_YOUTUBE,
  },
];

const legal = [
  {
    title: "Aviso Legal",
    url: PathRoutes.PATH_LEGAL_LEGALNOTICE,
  },
  {
    title: "Condiciones de contratación",
    url: PathRoutes.PATH_LEGAL_TERMSOFSERVICE,
  },
  {
    title: "Política de privacidad",
    url: PathRoutes.PATH_LEGAL_PRIVACYPOLICY,
  },
  {
    title: "Política de cookies",
    url: PathRoutes.PATH_LEGAL_COOKIEPOLICY,
  },
  {
    title: "Política de contenidos",
    url: PathRoutes.PATH_LEGAL_CONTENTPOLICY,
  },
  {
    title: "Cancelación, devoluciones y envíos",
    url: PathRoutes.PATH_LEGAL_CANCELLATIONRETURNSANDSHIPPINGPOLICY,
  },
];

export const FooterNavigation = {
  social,
  legal,
};

export default FooterNavigation;
