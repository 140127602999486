import React from "react";
import _ from "lodash/fp";
import parse from "html-react-parser";

import { Imgs, Texts, Credits as Options } from "../../data/Data";
import { Button } from "semantic-ui-react";
import { useCreditsBuy } from "../../data/api/useCredits";

const Credits = () => {
  const currencyOptions = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const currencyFormat = new Intl.NumberFormat("es-ES", currencyOptions);

  const { trigger, isMutating } = useCreditsBuy();

  const buyCredits = ({ credits, price }) => {
    trigger({ credits, price });
  };

  return (
    <main className="credits">
      <div
        className="credits-hero"
        style={{ backgroundImage: `url(${Imgs.credits.hero})` }}
      >
        <div className="credits-hero-content">
          <h5>{Texts.credits.hero.preTitle}</h5>
          <h1>{Texts.credits.hero.title}</h1>
          <h2>{parse(Texts.credits.hero.subTitle)}</h2>
        </div>
      </div>

      <div className="credits-options">
        {_.map(({ credits, price, promotion }) => (
          <div key={credits} className="credits-options-option">
            <h5 className="credits-options-option-credits">
              {credits} {Texts.credits.options.credits}
            </h5>
            <p className="credits-options-option-price">
              {currencyFormat.format(price / 100)}
            </p>
            <Button
              primary
              className="credits-options-option-button"
              credits={credits}
              price={price}
              onClick={(_e, data) => buyCredits(data)}
            >
              {isMutating
                ? Texts.credits.options.processing
                : Texts.credits.options.buy}
            </Button>
            {promotion ? (
              <p className="credits-options-option-promotion">
                {Texts.credits.options.save}{" "}
                {currencyFormat.format(promotion / 100)}
              </p>
            ) : null}
          </div>
        ))(Options)}
      </div>
    </main>
  );
};

export default Credits;
