/* header */
import headerLogo from "../assets/img/header/logo.svg";

/* home */
import homeHero from "../assets/img/home/home-hero-bg.jpg";
import homeFeature1 from "../assets/img/home/home-feature-1.jpg";
import homeFeature2 from "../assets/img/home/home-feature-2.jpg";
import homeFeature3 from "../assets/img/home/home-feature-3.jpg";
import homeFeature4 from "../assets/img/home/home-feature-4.jpg";
import homeService1 from "../assets/img/home/home-service-1.svg";
import homeService2 from "../assets/img/home/home-service-2.svg";
import homeService3 from "../assets/img/home/home-service-3.svg";
import homeService4 from "../assets/img/home/home-service-4.svg";
import homeHighlight from "../assets/img/home/home-highlight-bg.jpg";

/* channel */
import channelSocialIconX from "../assets/img/channel/social-icon-x.svg";
import channelSocialIconOnlyfans from "../assets/img/channel/social-icon-of.svg";

/* content creators */
import contentCreatorsHero from "../assets/img/content-creators/content-creators-bg.jpg";
import contentCreatorsFeature1 from "../assets/img/content-creators/content-creators-feature-1.jpg";
import contentCreatorsFeature2 from "../assets/img/content-creators/content-creators-feature-2.jpg";
import contentCreatorsFeature3 from "../assets/img/content-creators/content-creators-feature-3.jpg";
import contentCreatorsFeature4 from "../assets/img/content-creators/content-creators-feature-4.jpg";
import contentCreatorsService1 from "../assets/img/content-creators/content-creators-service-1.svg";
import contentCreatorsService2 from "../assets/img/content-creators/content-creators-service-2.svg";
import contentCreatorsService3 from "../assets/img/content-creators/content-creators-service-3.svg";
import contentCreatorsService4 from "../assets/img/content-creators/content-creators-service-4.svg";
import contentCreatorsService5 from "../assets/img/content-creators/content-creators-service-5.svg";
import contentCreatorsService6 from "../assets/img/content-creators/content-creators-service-6.svg";
import contentCreatorsService7 from "../assets/img/content-creators/content-creators-service-7.svg";
import contentCreatorsService8 from "../assets/img/content-creators/content-creators-service-8.svg";
import contentCreatorsHighlight from "../assets/img/content-creators/content-creators-highlight-bg.jpg";

/* platform */
import platformHero from "../assets/img/platform/platform-hero-bg.jpg";
import platformContent1 from "../assets/img/platform/platform-content-1.jpg";
import platformContent2 from "../assets/img/platform/platform-content-2.jpg";
import platformfeatures from "../assets/img/platform/platform-features-icon.svg";
import platformHighlight from "../assets/img/platform/platform-highlight-bg.jpg";

/* credits */
import creditsHero from "../assets/img/credits/credits-bg.jpg";

/* about */
import aboutImage from "../assets/img/about/about-image.jpg";

/* session */
import sessionManagerImg from "../assets/img/session/session-manager-img.jpg";

/* header */
export const headerImgs = {
  logo: headerLogo,
};

/* home */
export const homeImgs = {
  hero: homeHero,
  features: [homeFeature1, homeFeature2, homeFeature3, homeFeature4],
  services: [homeService1, homeService2, homeService3, homeService4],
  highlight: homeHighlight,
};

export const contentCreatorsImgs = {
  hero: contentCreatorsHero,
  features: [
    contentCreatorsFeature1,
    contentCreatorsFeature2,
    contentCreatorsFeature3,
    contentCreatorsFeature4,
  ],
  services: [
    contentCreatorsService1,
    contentCreatorsService2,
    contentCreatorsService3,
    contentCreatorsService4,
    contentCreatorsService5,
    contentCreatorsService6,
    contentCreatorsService7,
    contentCreatorsService8,
  ],
  highlight: contentCreatorsHighlight,
};

export const aboutImgs = {
  image: aboutImage,
};

/* channel */
export const chanelImgs = {
  social: {
    x: channelSocialIconX,
    onlyfans: channelSocialIconOnlyfans,
  },
};

/* platform */
export const platformImgs = {
  hero: platformHero,
  content: [platformContent1, platformContent2],
  features: platformfeatures,
  highlight: platformHighlight,
};

/* credits */
export const creditsImgs = {
  hero: creditsHero,
};

/* session */
export const sessionImgs = {
  img: sessionManagerImg,
};

export const Imgs = {
  header: headerImgs,
  home: homeImgs,
  channel: chanelImgs,
  contentCreators: contentCreatorsImgs,
  about: aboutImgs,
  platform: platformImgs,
  credits: creditsImgs,
  session: sessionImgs,
};
