const Navigation = {
  messages: {
    signup: {
      header: "Regístrate",
      content: [
        "Regístrate como usuario para acceder a contenido exclusivo",
        "Regístrate como anunciante y comienza a monetizar por tu contenido",
      ],
      actions: {
        user: "Registrarme como Usuario",
        manager: "Registrarme como Anunciante",
      },
    },
  },
};

export default Navigation;
