export const AccountChannel = {
  disclaimer:
    "ATENCIÓN: Para publicar un canal debes saber que el incumplimiento de determinadas reglas de publicación tales como <strong> aportar datos o fotos falsas, el uso de terceros sin autorización o el uso de tu perfil para cometer un delito </strong> implicará su eliminación y que el importe que hayas pagado sea retenido en concepto de gastos de tramitación de baja por incumplimiento.",
  saveNew: "Guardar nuevo canal",
  saveEdit: "Guardar cambios",
  select: "Selecciona",
  selectAdd: "Selecciona o añade",
  form: {
    channel: { label: "Canal (único en la plataforma)" },
    display_name: { label: "Nombre para mostrar" },
    type: {
      label: "Tipo de canal",
      options: {
        girl: "Chica",
        boy: "Chico",
        gay: "Gay",
        trans: "Trans",
        onlyfans: "OnlyFans",
        rooms: "Habitaciones",
      },
    },
    tags: {
      label: "Etiquetas",
      options: {
        girl: "Chica",
        boy: "Chico",
        black: "Morena",
        blonde: "Rubia",
        redhair: "Peliroja",
        tall: "Alta",
        short: "Baja",
        english: "Ingles",
        french: "Frances",
        spanish: "Español",
        international: "Internacional",
      },
    },
    short_description: { label: "Descripción corta" },
    description: { label: "Descripción" },
    phone: { label: "Teléfono" },
    whatsapp: { label: "Contacto por WhatsApp" },
    social_instagram: { label: "Instagram" },
    social_x: { label: "X (Twitter)" },
    social_onlyfans: { label: "OnlyFans" },
    social_other: { label: "Otra" },
    credit_card: { label: "Acepta pagos con tarjeta" },
    other_payments: { label: "Acepta otros pagos (Bizum, etc)" },
    country: { label: "País" },
    province: { label: "Provincia" },
    city: { label: "Localidad" },
    zone: { label: "Zona" },
    languajes: { label: "Idiomas" },
    map: { label: "Dirección (para generar mapa)" },
    interests: {
      label: "Principales aficiones",
      options: {
        meditation: "Meditación",
        fitness: "Fitness",
        beauty: "Belleza",
        cooking: "Cocinar",
        desserts_and_cakes: "Postres y pasteles",
        smoothies_and_juices: "Batidos y zumos",
        household_help: "Ayuda en casa",
        my_chores: "Mis labores",
        decoration: "Decoración",
        social_media: "Redes sociales",
        online_shopping: "Compras online",
        online_sales: "Ventas online",
        computer_science: "Informática",
        crafts: "Manualidades",
        other_cultures: "Otras culturas",
        games: "Juegos",
        video_games: "Videojuegos",
        shows_and_movies: "Series y pelis",
        spontaneous_outings: "Salidas improvisadas",
        party: "Salir de fiesta",
        shows: "Espectáculos",
        dancing: "Bailar",
        outdoor_activities: "Aire libre",
        museums: "Museos",
        indoor_sports: "Deportes indoor",
        fashion: "Moda",
        automotive: "Motor",
        nature: "Naturaleza",
        foreign_travel: "Viajes al extranjero",
        technology: "Tecnología",
        beach_trip: "Ir a la playa",
        group_outing: "Salir en grupo",
        wiki_wiki_meow_meow: "Wiki-wiki Miau-miau",
        massages: "Masajes",
      },
    },
    picture: {
      add: "Añadir",
      edit: "Editar",
      remove: "Eliminar",
      error: "Error en el formato de imagen. Ha de ser JPG, JPEG o PNG",
      channelThumbnail: {
        label: "Foto de portada",
      },
      channelTopVertical: {
        label: "Foto de destacado vertical",
      },
      channelTopHorizontal: {
        label: "Foto de destacado horizontal",
      },
    },
    pictures: {
      label: "Galería (Inserta hasta 4 fotos)",
      add: "Añadir",
      edit: "Editar",
      remove: "Eliminar",
      move_left: "Mover a la izquierda",
      move_right: "Mover a la derecha",
      error: "Error en el formato de imagen. Ha de ser JPG, JPEG o PNG",
    },
    videos: {
      label: "Inserta hasta 2 vídeos",
      add: "Añadir",
      edit: "Editar",
      error: "Error en el formato de vídeo. Ha de ser MP4, MPEG o AVI",
    },
    audios: {
      label: "Inserta hasta 2 audios",
      add: "Añadir",
      edit: "Editar",
      error: "Error en el formato de audio. Ha de ser MP3, WAV o OGG",
    },
    submit: { label: "Guardar" },
  },
  messages: {
    success: {
      new: {
        header: "Canal creado",
        content: ["La información del canal se ha actualizado correctamente"],
        actions: {
          ok: "Aceptar",
        },
      },
      edit: {
        header: "Canal actualizado",
        content: ["La información del canal se ha actualizado correctamente"],
        actions: {
          ok: "Aceptar",
        },
      },
      media: {
        header: "Media actualizada",
        content: ["Los vídeos se han subido correctamente"],
        actions: {
          ok: "Aceptar",
        },
      },
    },
  },
};

export default AccountChannel;
