import useSWRMutation from "swr/mutation";
import fetch from "unfetch";
import {
  API_LOGIN,
  API_SIGNUP_USER,
  API_SIGNUP_MANAGER,
  API_PASSWORD_RECOVERY,
} from "./endpoints";

const fetcher = async (url, payload) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

const useSession = (url) => {
  return useSWRMutation(url, (url, { arg }) => {
    return fetcher(url, arg);
  });
};

export const useSignup = (type) => {
  const url = type === "manager" ? API_SIGNUP_MANAGER : API_SIGNUP_USER;
  return useSession(url);
};

export const useLogin = () => {
  return useSession(API_LOGIN);
};

export const usePasswordRecovery = () => {
  return useSession(API_PASSWORD_RECOVERY);
};
