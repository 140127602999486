export const SessionPasswordRecovery = {
  title: "Recupera tu acceso",
  recovery: {
    title: "Te mandaremos un email con instrucciones",
    form: {
      user: { label: "Email Usuario" },
      submit: { label: "Enviar" },
    },
  },
  messages: {
    success: {
      header: "Correo enviado",
      content: [
        "Si tenemos una cuenta de correo asociada a tu cuenta, recibirás un correo",
        [
          "Si no aparece, comprueba tu bandeja de spam",
          "Si ya has recordado la contraseña, vuelve a iniciar sesión",
        ],
      ],
      actions: {
        ok: "Volver a iniciar sesión",
      },
    },
  },
};

export default SessionPasswordRecovery;
