import { Imgs } from "../Imgs";

export const Platform = {
  hero: {
    preTitle: "La plataforma",
    title: "Método Viva",
  },
  content: [
    {
      title: "Un verdadero Nirvana del contenido y producto porno",
      description:
        "Donde reina la libertad absoluta y el sexo real, sexo de disfrute y sexo épico. Por eso decimos que somos un Ludiverso de entretenimiento para adultos, porque diseñamos todos los productos y todos los servicios para elevar a otro nivel el disfrute, la interacción y el aprendizaje del sexo.",
    },
    {
      title: "La plataforma definitiva de entretenimiento adulto",
      description:
        "En VivaLaVidaLoca lo tenemos muy claro. Hemos creado El Nirvana del entretenimiento adulto La única plataforma en español para personas que viven, disfrutan y elevan su placer.",
    },
  ],
  features: [
    {
      text: "Otro nivel de <br />entretenimiento ",
      description:
        "No solo queremos hacer que el sexo sea un entretenimiento sano, natural y divertido. Buscamos potenciar al máximo a los creadores de contenido, para que sea un contenido épico e impresionante. Buscamos que todo el mundo llegue al máximo placer y la máxima diversión con todo lo que hacemos y creamos dentro de la plataforma.",
      icon: Imgs.platform.features[0],
    },
    {
      text: "Otro nivel de <br />interacción.",
      description:
        "Porque no queremos ser una plataforma ajena a nuestra audiencia, por eso incentivamos la magia de la verdadera interacción entre todos los miembros de la comunidad. Para que se logre más fantasía, más entretenimiento y una auténtica revolución sexual.",
      icon: Imgs.platform.features[1],
    },
    {
      text: "Un verdadero <br />contenido épico. ",
      description:
        "No queremos ser un espacio de contenido para adultos sin criterio, sino al contrario, impulsamos a los creadores amateurs para que creen contenido cada vez más potente. Nuestro soporte y herramientas están creadas para que todos puedan llegar a ser creadores de contenido profesionales.",
      icon: Imgs.platform.features[2],
    },
    {
      text: "Acompañados por <br />expertos reales.",
      description:
        "Valoramos cuando el disfrute que se hace correctamente, por eso hablamos de manera transparente y estamos abalados por expertos reales del sexo. Incentivamos la educación sexual, el verdadero aprendizaje sin tabúes y los conocimientos que aporten más valor y más disfrute a las relaciones sexuales.",
      icon: Imgs.platform.features[3],
    },
  ],
  higlight: {
    text: "Para quienes buscan <br />los placeres más elevados",
    cta: "Descubre más",
  },
};

export default Platform;
