const SessionSignupForm = [
  { name: "type", type: "hidden" },
  { name: "user", type: "email", is_required: true },
  { name: "alias", type: "text", is_required: true },
  { name: "password", type: "password", is_required: true },
  {
    name: "password_repeat",
    type: "password",
    is_required: true,
    must_match: "password",
  },
  { name: "submit", type: "submit" },
];

export const SessionSignupManagerForm = [
  { name: "name", type: "hidden" },
  { name: "surname", type: "hidden" },
  { name: "phone", type: "hidden" },
  { name: "phone2", type: "hidden" },
  { name: "whatsapp", type: "hidden" },
  { name: "submit", type: "submit" },
];

export default SessionSignupForm;
