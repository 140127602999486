import React from "react";
import { Texts } from "../data/Texts";
import { Button } from "semantic-ui-react";
import { useCookies } from "react-cookie";
import _ from "lodash/fp";

export const Cookies = () => {
  const [cookies, setCookie] = useCookies(["cookies-acceptance"]);

  return (
    <>
      {!_.get("cookies-acceptance")(cookies) ? (
        <div className="cookies">
          <div className="cookies-container">
            <p className="cookies-container-message">{Texts.cookies.message}</p>
            <Button
              primary
              className="cookies-container-cta"
              onClick={() =>
                setCookie("cookies-acceptance", 1, { maxAge: 2592000 })
              }
            >
              {Texts.cookies.cta}
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Cookies;
