import { Imgs } from "../Imgs";

export const ContentCreators = {
  hero: {
    preTitle: "El Nirvana del entretenimiento adulto",
    title: "VivaLaVidaLoca",
    subTitle: "Crea contenido real <br />potente y épico",
    cta: {
      login: "Accede a tu área",
      signup: "Date de alta",
    },
  },
  features: [
    { text: "Sube el nivel", bg: Imgs.contentCreators.features[0] },
    { text: "Supérate constantemente", bg: Imgs.contentCreators.features[1] },
    { text: "Monetiza mucho más", bg: Imgs.contentCreators.features[2] },
    {
      text: "Fideliza y crece tu audiencia",
      bg: Imgs.contentCreators.features[3],
    },
  ],
  services: {
    title: "Te ayudamos a alcanzar otro nivel de creacionest",
    subtitle:
      "Date de alta en el área de Creadores y empieza a cambiar la forma en que haces contenido. <br />Explora, aprende y ten resultados.",
    services: [
      {
        text: "Acceso a herramientas de interacción a otro nivel",
        description:
          "Nosotros te damos todas las herramientas de interacción que necesitas para conocer al máximo a tus fans y poder crear contenido más potente e interesante gracias al streaming, juegos, chating y otras herramientas.",
        icon: Imgs.contentCreators.services[0],
      },
      {
        text: "Comisión justa y por objetivos",
        description:
          "Tu eres el protagonista y por eso hemos ajustado los servicios de la plataforma en base a unos objetivos, para que aumentes la potencia y ganes más dinero, por eso te ayudamos en tu crecimiento de tu cuenta para que monetices al máximo a tu audiencia.",
        icon: Imgs.contentCreators.services[1],
      },
      {
        text: "Política de libertad nivel Dios",
        description:
          "Porque queremos que seas tú mismo. Ya que lo más divertido y autentico es la originalidad de las personas; por eso en la plataforma existe la plena libertad de expresión y de contenido.",
        icon: Imgs.contentCreators.services[2],
      },
      {
        text: "Acceso a Salto",
        description:
          "Para que evoluciones tu perfil de creador y además te superes constantemente. Dispones de la biblioteca más potente de contenido épico, contenido educativo y del aula de consultoría con profesionales del sexo, creadores, asesores de imagen, redes, etc Ya que con tus ganas de darlo todo y esta gran herramienta seguro que no pararas de crecer en lo profesional y en lo personal.",
        icon: Imgs.contentCreators.services[3],
      },
      {
        text: "Plataforma poderosa, fácil e intuitiva",
        description:
          "Porque hemos creado El verdadero Nirvana para adultos donde tu eres el protagonista y de ahí la importancia en el diseño de la plataforma, que es muy fácil, intuitiva y dirigida a personas de habla hispana. También hemos incorporado herramientas de edición entre otras, para que no tengas obstáculo a la hora de crear tu contenido.",
        icon: Imgs.contentCreators.services[4],
      },
      {
        text: "Monetiza mejor tu audiencia",
        description:
          "Gracias a las herramientas de interacción, al diseño de la plataforma, a la política de libertad, al acceso a SALTO y a la comisión justa y por objetivos; en VivaLaVidaLoca monetizaras mejor a tu audiencia que en cualquier otra plataforma del mercado.",
        icon: Imgs.contentCreators.services[5],
      },
      {
        text: "Tu saldo solo en 7 días",
        description:
          "Y ni uno más, ten el saldo de tu monedero a tu cuenta bancaria en solo una semana.",
        icon: Imgs.contentCreators.services[6],
      },
      {
        text: "Acceso a herramientas de interacción a otro nivel",
        description:
          "Nosotros te damos todas las herramientas de interacción que necesitas para conocer al máximo a tus fans y poder crear contenido más potente e interesante gracias al streaming, juegos, chating y otras herramientas.",
        icon: Imgs.contentCreators.services[7],
      },
    ],
  },
  higlight: {
    text: "Hemos creado tu nirvana <br /> para que puedas crear contenido épico.",
    cta: "Descubre cómo",
  },
};

export default ContentCreators;
