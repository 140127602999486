import React from "react";
import { map } from "lodash/fp";
import parse from "html-react-parser";

import { Imgs, Texts, PathRoutes } from "../../data/Data";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const ContentCreators = () => {
  return (
    <main className="content-creators">
      <div
        className="content-creators-hero"
        style={{ backgroundImage: `url(${Imgs.contentCreators.hero})` }}
      >
        <div className="content-creators-hero-content">
          <h5>{Texts.contentCreators.hero.preTitle}</h5>
          <h1>{Texts.contentCreators.hero.title}</h1>
          <h2>{parse(Texts.contentCreators.hero.subTitle)}</h2>
          <Button primary as={Link} to={PathRoutes.PATH_SESSION_SIGNUP_MANAGER}>
            {Texts.contentCreators.hero.cta.signup}
          </Button>
        </div>
      </div>

      <div className="content-creators-features">
        {map.convert({ cap: false })((feature, k) => (
          <div
            className="content-creators-features-feature"
            style={{ backgroundImage: `url(${feature.bg})` }}
            key={k}
          >
            <div className="content-creators-features-feature-content">
              <h4>{feature.text}</h4>
            </div>
          </div>
        ))(Texts.contentCreators.features)}
      </div>

      <div className="content-creators-services">
        <div className="content-creators-services-heading">
          <h2>{Texts.contentCreators.services.title}</h2>
          <p>{parse(Texts.contentCreators.services.subtitle)}</p>
        </div>
        <div className="content-creators-services-content">
          {map.convert({ cap: false })((service, k) => (
            <div className="content-creators-services-content-service" key={k}>
              <div className="content-creators-services-content-service-content">
                <img
                  className="content-creators-services-content-service-content-icon"
                  src={service.icon}
                  alt=""
                />
                <h6 className="content-creators-services-content-service-content-title">
                  {service.text}
                </h6>
                <p className="content-creators-services-content-service-content-description">
                  {service.description}
                </p>
              </div>
            </div>
          ))(Texts.contentCreators.services.services)}
        </div>
      </div>

      <div
        className="content-creators-highlight"
        style={{ backgroundImage: `url(${Imgs.contentCreators.highlight})` }}
      >
        <div className="content-creators-highlight-content">
          <h3>{parse(Texts.contentCreators.higlight.text)}</h3>
          <Button primary as={Link} to={PathRoutes.PATH_PLATFORM}>
            {Texts.contentCreators.higlight.cta}
          </Button>
        </div>
      </div>
    </main>
  );
};

export default ContentCreators;
