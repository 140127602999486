export const LegalLegalNotice = {
  title: "AVISO LEGAL y CONDICIONES GENERALES DE USO",
  content: `
<p>El presente AVISO LEGAL y las CONDICIONES GENERALES DE USO DE LA PÁGINA regulan el uso de la página <a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>, la cual ha sido creada para promocionar servicios publicitarios. En aplicación del artículo 10 de la&nbsp;<em>Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico,&nbsp;</em>(en adelante, LSSI) en el siguiente clausulado se identifica al titular de la página web&nbsp;<a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>, así como los requerimientos para navegar por la mencionada página web.</p>

<p><strong>DEFINICIONES</strong></p>

<ul>
<li><strong>Profesional.-&nbsp;</strong>cualquier persona física que acceda a la plataforma&nbsp;<a href="https://www.viptoenjoy.com">www.viptoenjoy.com</a>, con el objetivo de crear un perfil público, para publicar contenido publicitario sobre la prestación de sus servicios en la plataforma&nbsp;<a href="https://www.viptoenjoy.com">www.viptoenjoy.com</a>.</li>
<li><strong>Clientes.-&nbsp;</strong>cualquier persona física que acceda a la plataforma&nbsp;<a href="https://www.viptoenjoy.com">www.viptoenjoy.com</a>, que ha configurado un perfil con acceso a todos los servicios de la plataforma&nbsp;<a href="https://www.viptoenjoy.com">www.viptoenjoy.com</a>.</li>
<li><strong>Usuario.-&nbsp;</strong>cualquier navegante que acceda a la plataforma&nbsp;<a href="https://www.viptoenjoy.com">www.viptoenjoy.com</a>, que no ha configurado un perfil. Sin embargo, puede tener acceso a la plataforma&nbsp;<a href="https://www.viptoenjoy.com">www.viptoenjoy.com</a>, para la consulta y contacto de perfiles a través del teléfono que publican en LA PLATAFORMA.</li>
<li><strong>Contenido.-&nbsp;</strong>cualquier material publicitario subido a la plataforma&nbsp;<a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>&nbsp;por un anunciante, incluyendo fotos, videos, audios, datos, textos (comentarios y hashtags), metadatos, emojis, GIFs y cualquier otro material.</li>
<li><strong>Transacción profesional/usuario.-&nbsp;</strong>cualquier transacción entre el/la profesional y un usuario, mediante la cual el usuario contacta con la profesional para la contratación de la prestación de servicios anunciados en su perfil.<strong></strong></li>
<li><strong>Acuerdo profesional/usuario.-&nbsp;</strong>significa los términos que rigen cada transacción entre el/la profesional y el usuario, al margen de los responsables de la plataforma&nbsp;<a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>.</li>
<li><strong>Textos Legales.-&nbsp;</strong>se refiere a todos los documentos jurídicos que debe contener LA PLATAFORMA, para adecuarse a la normativa vigente&nbsp;<a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>: (i) el presente Aviso Legal y las presentes Condiciones de Uso de la página web, (ii) la Política de Privacidad, (iii) la Política de Cookies (iv) las Condiciones Particulares de la contratación.</li>
</ul>

<p><strong>&nbsp;1. DATOS IDENTIFICATIVOS&nbsp;&nbsp;</strong><a href="http://www.viptoenjoy.com"><strong>www.viptoenjoy.com</strong></a></p>

<p>Los productos y los servicios de ViptoEnjoy.com (en adelante, los “Servicios”) se proporcionan a través de Viptoenjoy, S.L. (en adelante, «ViptoEnjoy.com»), con la dirección ViptoEnjoy, c/Divina providencia 08800 Vilanova i la Geltrú, Barcelona y con Número de Identificación Fiscal B66684143 e inscrita en el Registro Mercantil de Barcelona, Tomo 45200, Folio 99, Inscripción 1, Hoja B 480072.</p>

<p>1.2.<strong>&nbsp;</strong>El presente AVISO LEGAL regula el uso de la página web&nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>, la cual ha sido creada para proporcionar servicios publicitarios y de intermediación.</p>

<p><strong>2. ACEPTACIÓN DE LAS CONDICIONES DE USO</strong></p>

<p>2.1. Las presentes condiciones tienen por objeto regular el uso de&nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>&nbsp;al poner a disposición del público esta URL.&nbsp;</p>

<p>2.2. Las presentes condiciones de uso establecen sus derechos y responsabilidades a aquellos terceros que utilizan&nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>, y supone la aceptación plena por los diferentes terceros de todas y cada una de las condiciones que se incorporan a las condiciones de uso de esta página.&nbsp;&nbsp;</p>

<p>2.3.&nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>&nbsp;es una plataforma digital que permite contratar servicios publicitarios, facilitando los espacios publicitarios, pero no es parte de ningún acuerdo entre los profesionales que anuncian sus servicios en la plataforma y usuarios finales que, a través de los mencionados anuncios, establecen contacto con aquellos.</p>

<p>2.4. Tanto el/la profesional, los clientes y usuarios finales de la plataforma se obligan a hacer un uso correcto del sitio web de conformidad con las leyes, la buena fe, el orden público, los usos del tráfico, el presente Aviso Legal, las Condiciones de Uso de la Página y responderán frente a el titular de la plataforma o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de sus obligaciones.</p>

<p>Cualquier utilización distinta a la autorizada está expresamente prohibida, pudiendo el titular de la plataforma denegar o retirar el acceso y su uso en cualquier momento.</p>

<p><strong>3. OBJETO Y ÁMBITO DE APLICACIÓN</strong></p>

<p>3.1. La página web&nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>&nbsp;pone a disposición de los/las profesionales, clientes y usuarios finales el acceso a la información, datos y su contenido, mediante la navegación a través de la mencionada web.</p>

<p>3.2. Las presentes condiciones generales de uso de la plataforma regulan el acceso, navegación y uso del sitio web&nbsp;&nbsp;<a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>, así como las responsabilidades derivadas de la utilización de sus contenidos, entendiendo por estos cualquier textos, gráficos, imágenes, vídeos, sonidos, imágenes, expresiones, publicidad, informaciones y otras creaciones y/o contenidos incluidos en la plataforma que sean utilizados por los/las profesionales para la promoción de sus servicios.</p>

<p>3.3. El titular de la plataforma proporciona a los/las profesionales, clientes y usuarios finales acceso a contenidos o espacios publicitarios, cuya titularidad íntegramente pertenecen a&nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>.</p>

<p>3.4. Se entenderá que el acceso o la mera utilización a &nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>, por parte de los/las profesionales, clientes y usuarios finales implica la adhesión de estos a las condiciones generales que hayan sido publicadas en cada momento que se acceda a la página. Por lo tanto, el/la profesional, cliente y usuario (personas que accede, navega y utiliza determinados contenidos, servicios y actividades desarrolladas por&nbsp;<a href="http://www.destacamos.com/"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>, creando perfiles o contratando alguno de los servicios de la página web) deberán leer atentamente estas condiciones generales de uso de la página.</p>

<p>3.5. Antes de utilizar los servicios publicitarios prestados por&nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>&nbsp;los/las profesionales, clientes y usuarios finales deberán leer atentamente las presentes condiciones. La utilización y/o la contratación de dichos servicios específicos implica la aceptación de las condiciones generales y particulares que los regulen en la versión publicada en&nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>&nbsp;en el momento en el que se produzca dicha utilización.</p>

<p><strong>4. TÉRMINOS Y CONDICIONES</strong></p>

<p>4.1. Los contenidos y servicios incluidos en&nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>&nbsp;no están concebidos, ni dirigidos a aquellas personas que residan en jurisdicciones donde sus contenidos no se encuentren autorizados.</p>

<p>4.2. El acceso a&nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>&nbsp;es responsabilidad exclusiva de los/las profesionales, clientes y usuarios finales. Los/las profesionales, clientes y usuarios asumen la responsabilidad del uso del portal, el cual se extiende al registro que fuese necesario para acceder a determinados servicios o contenidos. Los/las profesionales, clientes y usuarios se comprometen a proporcionar información veraz al registrarse. En dicho registro el usuario será responsable de aportar información veraz, lícita y una contraseña, de la que hará un uso diligente y confidencial.</p>

<p>4.3. El acceso a la navegación por&nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>&nbsp;no supone entablar ningún tipo de relación de carácter comercial entre El titular de la plataforma y el usuario.</p>

<p>4.4. El acceso y la navegación en&nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>&nbsp;supone aceptar y conocer las advertencias legales, condiciones y términos de uso contenidas en ellas. Concretamente, el/la profesional, cliente y usuario se obligan a realizar un uso adecuado de los contenidos y servicios, y con carácter enunciativo pero no limitativo, a no emplearlos para:</p>

<p>(i) Difundir contenido fraudulento, difamatorio, que fomente el odio, amenazador o o acosador, que promueva la violencia.</p>

<p>(ii) Difundir contenidos de carácter racista, xenófobo, pornográfico, de&nbsp;&nbsp; apología del terrorismo o atentatorio contra los derechos humanos.</p>

<p>(iii) Causar daños a&nbsp;<a href="https://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>, a sus proveedores o a terceras personas.</p>

<p>(iv) Introducir o difundir cualquier tipo de virus informáticos que sean susceptibles de provocar daños a&nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>.</p>

<p>(v) Acceder y, en su caso, utilizar las cuentas de correo electrónico de otros los/las profesionales y usuarios finales y modificar o manipular sus mensajes.</p>

<p>(vi) Descompilar, desensamblar, realizar ingeniería inversa, o intentar descubrir o derivar el código fuente de &nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>.</p>

<p>(vii)Utilizar identidades falsas durante la navegación por&nbsp;www.viptoenjoy.com.</p>

<p>(viii) utilizar la plataforma de una manera que pueda afectar negativamente a nuestros sistemas o seguridad o interferir con el uso de &nbsp;www.viptoenjoy.com&nbsp;de cualquier otro usuario, incluyendo su capacidad para participar en actividades en tiempo real a través de &nbsp;www.viptoenjoy.com.</p>

<p>(ix) Utilizar programas automatizados, herramientas o procesos como rastreadores web, robots, bots, arañas y scripts automatizado, para acceder a la plataforma o cualquier servidor, red o sistema asociado con&nbsp;www.viptoenjoy.com, o para extraer, recoger o recopilar contenido o información de&nbsp;www.viptoenjoy.com.</p>

<p>(x) Utilizar el nombre de DESTACAMOS como logotipo o cualquier nombre relacionado o similar, logotipos, nombres de productos y servicios, diseños o eslóganes que sean titularidad de la plataforma.</p>

<p><strong>5. ACTUALIZACIÓN Y MODIFICACIÓN DE LA INFORMACIÓN</strong></p>

<p>5.1. La información que aparece en&nbsp;www.viptoenjoy.com es la vigente en la fecha de su última actualización, reservándose el derecho a actualizar, modificar o eliminar la información, pudiendo limitar o no permitir el acceso a las mismas.</p>

<p>5.2. No se podrá alterar, cambiar, modificar o adaptar&nbsp;www.viptoenjoy.com, salvo por los responsables de la gestión de contenidos de la página web. Sin embargo, los titulares de las mismas se reservan la facultad de efectuar, en cualquier momento, cuantos cambios y modificaciones estime convenientes, pudiendo hacer uso de tal facultad en cualquier momento y sin previo aviso. De este modo, se podrá modificar este Aviso Legal, siendo estas modificaciones efectivas en el momento en que se introduzca la nueva versión en&nbsp;www.viptoenjoy.com. &nbsp;&nbsp;Se entenderá que el los/las profesionales, clientes y usuarios finales se adhieren a cada actualización de cada Aviso Legal, por el mero acceso o utilización de&nbsp;www.viptoenjoy.com. Por lo tanto, el los/las profesionales, clientes y usuarios finales deberán leer periódicamente este Aviso Legal.</p>

<p>5.3. El idioma utilizado en&nbsp;www.viptoenjoy.com&nbsp;será el español, sin perjuicio de la utilización de otras lenguas nacionales o autonómicas.</p>

<p><strong>6. CONTENIDOS</strong></p>

<p>6.1.&nbsp;www.viptoenjoy.com&nbsp;realiza los máximos esfuerzos para evitar cualquier error en sus contenidos, no garantizando, ni responsabilizándose de las consecuencias que pudieran derivarse de los errores en los contenidos proporcionados por terceros.</p>

<p>6.2.&nbsp;www.viptoenjoy.com&nbsp;no se responsabiliza en modo alguno de aquellos contenidos y servicios incluidos en la página web que pudiesen visualizarse mediante enlaces electrónicos (links), directa o indirectamente, a través de su página web, que no hubiese autorizado su publicación. La presencia de links en&nbsp;www.viptoenjoy.com, salvo manifestación expresa en contrario, tiene una finalidad meramente informativa y, en ningún caso, supone sugerencia, invitación o recomendación sobre los mismos. Estos enlaces no representan ningún tipo de relación entre&nbsp;www.viptoenjoy.com&nbsp;y los particulares o empresas titulares de los websites a los que pueda accederse mediante estos enlaces. Asimismo,&nbsp;www.viptoenjoy.com&nbsp;se reserva el derecho de retirar de modo unilateral y en cualquier momento los links de terceros que aparecen en&nbsp;www.viptoenjoy.com.</p>

<p>6.3.&nbsp;www.viptoenjoy.com&nbsp;no asume responsabilidad por el contenido vertido por los/las profesionales, clientes y usuarios o cualquier tipo de transmisiones, que estén vinculadas a&nbsp;www.viptoenjoy.com&nbsp;y cooperarán, si es requerida por orden judicial o por las autoridades pertinentes, en la identificación de las personas responsables de aquellos contenidos que infrinjan la ley.</p>

<p>6.4.&nbsp;www.viptoenjoy.com&nbsp;excluye toda responsabilidad que pudiera derivarse de la transmisión de información entre los/las profesionales, clientes y usuarios finales. La responsabilidad de las manifestaciones difundidas en&nbsp;www.viptoenjoy.com&nbsp;es exclusiva de quienes las realizan. Queda prohibida cualquier tipo de transmisión de datos que los/las profesionales, clientes y usuarios finales puedan realizar a éste o mediante&nbsp;www.viptoenjoy.com&nbsp;a otros accesos controlados por&nbsp;www.viptoenjoy.com, que infrinjan los derechos de propiedad de terceros, y cuyo contenido sea amenazante o difamatorio</p>

<p>6.5.&nbsp;www.viptoenjoy.com&nbsp;se reserva el derecho a impedir o prohibir el acceso a cualquier los/las profesionales, clientes y usuarios finales que introduzcan en las mismas cualquier contenido contrario a las normas legales, reservándose el derecho de ejercer las medidas legales que estime oportunas para evitar este tipo de conductas.</p>

<p>6.6. Los editores de&nbsp;www.viptoenjoy.com&nbsp;podrán –si lo estiman necesario- modificar, alterar o eliminar cualquier contenido que atentase contra la dignidad de las personas o fuese contrario a las leyes vigentes.</p>

<p><strong>7. NAVEGACIÓN, ACCESO Y SEGURIDAD</strong></p>

<p>7.1. El acceso y navegación en&nbsp;www.viptoenjoy.com&nbsp;supone aceptar y conocer las advertencias legales, condiciones y términos de uso contenidas en él. El titular de la plataforma realizará los máximos esfuerzos para que la navegación se realice en las mejores condiciones y evitar los perjuicios de cualquier tipo que pudiesen ocasionarse durante la misma.</p>

<p>7.2.&nbsp;www.viptoenjoy.com&nbsp;ha sido diseñada para utilizar todas las versiones actualizadas de los navegadores, eximiéndose de cualquier responsabilidad derivada de los perjuicios, de cualquier índole, que pudieran ocasionarse a los/las profesionales y usuarios finales por la utilización de versiones no actualizadas de los navegadores.</p>

<p>7.3.&nbsp;www.viptoenjoy.com&nbsp;no se responsabiliza ni garantiza que el acceso a su página web sea ininterrumpido o que esté libre de error. Tampoco se responsabiliza o garantiza que el contenido o software al que pueda accederse a través de estas páginas, esté libre de error o cause un daño. En ningún caso,&nbsp;www.viptoenjoy.com&nbsp;será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el acceso y el uso, incluyéndose, pero no limitándose, a los ocasionados en los sistemas informáticos o los provocados por la introducción de virus. El titular de la plataforma no se hace responsables de los daños que pudiesen ocasionarse a los/las profesionales, clientes y usuarios finales por un uso inadecuado en la navegación por&nbsp;www.viptoenjoy.com. En particular, no se hace responsable en modo alguno de las caídas, interrupciones, falta o defecto de las telecomunicaciones que pudieran ocurrir en operaciones de naturaleza financiera.</p>

<p>7.4. Los servicios ofertados en la precitada página sólo pueden ser utilizados correctamente si se cumplen las especificaciones técnicas para los que han sido diseñados.</p>

<p><strong>8. PROPIEDAD INTELECTUAL E INDUSTRIAL</strong></p>

<p>8.1. El titular de la plataforma es titular sobre los derechos de explotación de propiedad intelectual e industrial de&nbsp;www.viptoenjoy.com, &nbsp;así como de los derechos de propiedad intelectual, industrial y de imagen sobre los contenidos disponibles a través de las mismas.</p>

<p>8.2. En ningún caso, se entenderá que el acceso y navegación de los/las profesionales, clientes y usuarios finales, implica una renuncia, transmisión, licencia o cesión total ni parcial de dichos derechos por parte de&nbsp;www.viptoenjoy.com.</p>

<p>8.3. En consecuencia, no está permitido suprimir, eludir o manipular el aviso de derechos de autor (“copyright”) y cualesquiera otros datos de identificación de los derechos de&nbsp;www.viptoenjoy.com&nbsp;o de sus titulares incorporados a los contenidos, así como los dispositivos técnicos de protección, las huellas digitales o cualesquiera mecanismos de información y/o de identificación que pudieren contenerse en los contenidos.</p>

<p>8.4. Asimismo, está prohibido modificar, copiar, reutilizar, explotar, reproducir, comunicar públicamente, hacer segundas o posteriores publicaciones, cargar archivos, enviar por correo, transmitir, usar, tratar o distribuir de cualquier forma la totalidad o parte de los contenidos incluidos en&nbsp;www.viptoenjoy.com, si se aceptan las condiciones de uso de la citada plataforma.</p>

<p><strong>9. PROTECCIÓN DE DATOS</strong></p>

<p>9.1. La visita a&nbsp;www.viptoenjoy.com&nbsp;no supone que los/las profesionales, clientes y usuarios finales estén obligado a facilitar ninguna información sobre si mismos.</p>

<p>En el caso de que el los/las profesionales, clientes y usuarios finales proporcione alguna información de carácter personal, los datos recogidos en&nbsp;www.viptoenjoy.com&nbsp;serán utilizados con la finalidad, en la forma, con las limitaciones y derechos, conforme a lo establecido en el&nbsp;<em>Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos</em>&nbsp;(en adelante, RGPD), así como en la normativa española vigente en esta materia, en concreto, la&nbsp;<em>Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.</em></p>

<p>En los casos en que, para realizar una solicitud, sea necesario cumplimentar el formulario, los datos facilitados por los/las profesionales, clientes y usuarios finales al titular de la plataforma será tratados de forma confidencial, siempre y cuando los/las profesionales y usuarios finales presten consentimiento explícito para el tratamiento de sus datos y acepte la Política de Privacidad de&nbsp;www.viptoenjoy.com. Estos datos se incorporarán a un fichero de datos de carácter personal del que será responsable el titular de la plataforma, donde serán conservados confidencialmente.</p>

<p>9.2. Los/las profesionales y usuarios finales que haya prestado sus datos personales otorgarán consentimiento explícito e individual para cada uno de los tratamientos, tales como (i) el mantenimiento y gestión de la relación contractual/precontractual, (ii) información sobre las ofertas y promoción de servicios, (iii) todo tipo de publicidad, (iv) sugerencias realizadas, (v) envío de newsletters, (vi) o cualquier otra actividad para la que hayan facilitado sus datos.</p>

<p>9.3. Mediante la cláusula incorporada en el formulario, se recabará el consentimiento necesario para que estos datos puedan ser cedidos a otras entidades con los que el titular de la plataforma venga colaborando.</p>

<p>9.4. El titular de la plataforma podrá conservar sus datos una vez finalizada la relación con los/las profesionales, clientes y usuarios finales, si fuese necesario para cumplir obligaciones legales.</p>

<p>9.5. En el caso de que los datos recogidos se utilizasen para una finalidad distinta para la cual hubiesen sido recabados o recogidos se requerirá nuevo consentimiento explícito de los interesados.</p>

<p>9.6. El titular de la plataforma ha adoptado las medidas técnicas y organizativas necesarias para garantizar la seguridad e integridad de los datos, así como para evitar su alteración, perdida, tratamiento o acceso no autorizado.</p>

<p>9.7. El titular de la plataforma &nbsp;le informa y garantiza todos los derechos que le asisten, previstos en el&nbsp;<em>Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos</em>&nbsp;(en adelante, RGPD), así como en la normativa española vigente en esta materia, en concreto, la&nbsp;<em>Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.</em>&nbsp;Por lo tanto, como profesionales y usuarios finales de&nbsp;www.viptoenjoy.com&nbsp;podrán ejercitar –en cualquier momento- los siguientes derechos:</p>

<ol>
<li>Derecho de acceso a sus datos personales para conocer su tratamiento.</li>
<li>Derecho de rectificación para que pueda modificar o actualizar en cualquier momento un dato de carácter personal.</li>
<li>Derecho de supresión de sus datos personales, cuando ya no sean necesarios para los fines que fueron recogidos.</li>
<li>Derecho de oposición, es decir, solicitar que no se traten sus datos de carácter personal.</li>
<li>Derecho a retirar su consentimiento en cualquier momento y sin justificar la causa de la revocación.</li>
<li>Derecho a solicitar la limitación del tratamiento de sus datos personales.</li>
<li>Derecho de portabilidad de sus datos.&nbsp;</li>
<li>Derecho a presentar reclamación ante la Agencia Española de Protección de Datos.</li>
</ol>

<p>9.8. La negativa a proporcionar los datos calificados como obligatorios supondrá la no prestación o la imposibilidad de acceder a determinados servicios para los que eran solicitados. Asimismo, podrán facilitarse datos de modo voluntario con el objetivo de que puedan prestarse de modo más óptimo los servicios ofertados.</p>

<p>9.9. El titular de la plataforma, según lo dispuesto en la legislación vigente en materia de protección de datos de carácter personal, informa a los/las profesionales clientes y usuarios finales que pueden consultar su Política de Privacidad, la cual será de aplicación en el tratamiento de datos de carácter personal, que sean facilitados por el los/las profesionales y usuarios finales cuando acceda, use y/o se registre en la página web&nbsp;www.viptoenjoy.com.</p>

<p>9.10. El titular de la plataforma comunica a los/las profesionales, clientes y usuarios finales que, para un correcto funcionamiento de la página web,&nbsp;www.viptoenjoy.com&nbsp;utiliza cookies propias y de terceros para mejorar sus servicios mediante el análisis de sus hábitos de navegación. Esta herramienta que se instala en el disco duro de los/las profesionales, clientes y usuarios finales se utiliza para el almacenamiento y la posterior recuperación de determinada información relativa al uso que se realiza de la página web, pero no recoge ningún dato personal que permita identificar a los/las profesionales, clientes y usuarios finales. Se puede obtener más información, o bien conocer cómo cambiar la configuración en nuestra Política de uso de cookies.</p>

<p><strong>10. LICENCIA SOBRE LAS COMUNICACIONES</strong></p>

<p>10.1. En el caso de que los/las profesionales y usuarios finales envíen información de cualquier tipo a&nbsp;www.viptoenjoy.com&nbsp;declarando, garantizando y aceptando que tienen derecho a hacerlo libremente, que dicha información no infringe ningún derecho de propiedad intelectual, de marca, de patente, secreto comercial, o cualquier otro derecho de tercero, que dicha información no tiene carácter confidencial y que dicha información no es perjudicial para terceros.</p>

<p>10.2. El los/las profesionales y usuarios finales reconocen asumir la responsabilidad y dejará indemne a&nbsp;www.viptoenjoy.com&nbsp;por cualquier comunicación que suministre personalmente o a su nombre, alcanzando dicha responsabilidad sin restricción alguna la exactitud, legalidad, originalidad y titularidad de la misma.</p>

<p><strong>11.- RESPONSABILIDADES Y GARANTÍAS</strong></p>

<p>11.1. El titular de la plataforma se exime de responsabilidad ante los daños que se pudieran ocasionar a través de la navegación por su página web&nbsp;www.viptoenjoy.com.</p>

<p>11.2. En consecuencia, el titular de la plataforma &nbsp;no garantizan (i) la ausencia de virus y/o demás componentes dañinos en&nbsp;www.viptoenjoy.com&nbsp;o en el servidor que lo suministra; (ii) la invulnerabilidad de las mismas y/o la inexpugnabilidad de las medidas de seguridad que se adopten en el mismo; (iii) la falta de utilidad o rendimiento de los contenidos de&nbsp;www.viptoenjoy.com; (iv) los daños o perjuicios que cause, a sí mismo o a un tercero, cualquier persona que infringiera las condiciones, normas e instrucciones que el titular de la plataforma &nbsp;establece en&nbsp;www.viptoenjoy.com&nbsp;o a través de la vulneración de los sistemas de seguridad de las mismas.</p>

<p>No obstante, el titular de la plataforma declara que ha adoptado todas las medidas necesarias, dentro de sus posibilidades y del estado de la tecnología, para garantizar el funcionamiento las mismas y evitar la existencia y transmisión de virus y demás componentes dañinos a los/las profesionales y usuarios finales.</p>

<p><strong>12. ENLACES</strong></p>

<p>Enlaces a otros sitios de Internet:</p>

<p>12.1. En el caso de que en&nbsp;www.viptoenjoy.com&nbsp;se dispusiesen enlaces o hipervínculos hacía otros sitios de Internet, el titular de la plataforma no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso,&nbsp;&nbsp;www.viptoenjoy.com&nbsp;asumirá responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o información contenida en ninguno de dichos hipervínculos u otros sitios de Internet.</p>

<p>12.2. El establecimiento de cualquier tipo de enlace por parte de&nbsp;www.viptoenjoy.com, a otro sitio de Internet ajeno no implica que exista algún tipo de relación, colaboración o dependencia con las entidades conectadas.</p>

<p>12.3. El titular de la plataforma no tiene facultad ni medios humanos y técnicos para conocer, controlar ni aprobar toda la información, contenidos, productos o servicios facilitados por otros sitios de Internet que tengan establecidos enlaces con destino a&nbsp;www.viptoenjoy.com. El titular de la plataforma no asume ningún tipo de responsabilidad sobre enlaces con destino a&nbsp;www.viptoenjoy.com, en concreto, a título enunciativo y no taxativo, sobre su funcionamiento, acceso, datos, información, archivos, calidad y fiabilidad de sus productos y servicios, sus propios enlaces y/o cualquiera de sus contenidos, en general.</p>

<p>Servicios prestados por terceros a través de&nbsp;www.viptoenjoy.com. &nbsp;&nbsp;</p>

<p>12.4. El titular de la plataforma no garantiza la licitud, fiabilidad y utilidad de los servicios prestados por terceros a través de esta página.</p>

<p>12.5. El titular de la plataforma no será responsable de los daños y perjuicios de toda naturaleza causados por los servicios prestados por terceros a través de su página, y en particular, a título meramente enunciativo, los causados por:</p>

<p>a) El incumplimiento de la ley.</p>

<p>b) La incorporación de virus o cualquier otro código informático, archivo o programa que pueda dañar, interrumpir o impedir el normal funcionamiento de cualquier software, hardware o equipo de telecomunicaciones.</p>

<p>c) &nbsp;La infracción de los derechos de propiedad intelectual e industrial, de los secretos empresariales, de compromisos contractuales de cualquier clase.</p>

<p>d) La realización de actos que constituyan publicidad ilícita, engañosa o desleal y, en general, que constituyan competencia desleal.</p>

<p>e) La falta de veracidad, exactitud, calidad, pertinencia y/o actualidad de los contenidos transmitidos, difundidos, almacenados, recibidos, obtenidos, puestos a disposición o accesibles.</p>

<p>g) &nbsp;La infracción de los derechos al honor, a la intimidad personal y familiar y a la imagen de las personas o, en general, cualquier tipo de derechos de terceros.</p>

<p>h) La inadecuación para cualquier clase de propósito y la defraudación de las expectativas generadas, o los vicios y defectos que pudieran generarse en la relación terceros.</p>

<p>i) El incumplimiento, retraso en el cumplimiento, cumplimiento defectuoso o terminación por cualquier causa de las obligaciones contraídas por terceros y contratos realizados con terceros.</p>

<p><strong>13. MENORES DE EDAD</strong></p>

<p>13.1. Queda terminantemente prohibido el acceso y uso de&nbsp;www.viptoenjoy.com&nbsp;a los menores de edad, toda vez que la plataforma es un sitio web estrictamente limitado a las personas mayores de edad.</p>

<p>13.2. El titular de la plataforma recuerda que será responsabilidad de los progenitores o tutores ejercer un control adecuado sobre la actividad de los hijos o menores a su cargo.</p>

<p>13.3. El titular de la plataforma prohíbe explotar, dañar o intentar explotar o dañar a cualquier persona menor de 18 años, exponiéndoles a cualquier contenido inapropiado.</p>

<p>13.4. El titular de la plataforma prohíbe a cualquier individuo menor de 18 años cargar, enviar, mostrar y publicar contenido en&nbsp;www.viptoenjoy.com. Del mismo modo, se prohíbe a cualquier usuario menor de 18 años contactar o contratar los servicios de las/los profesionales publicitados en la plataforma.</p>

<p>13.5. Habiendo realizado las anteriores advertencias, el titular de la plataforma no será responsable de cualquier tergiversación o engaño con respecto a la edad de un/una profesional, cliente y/o usuario.</p>

<p>13.6. Cualquier usuario que tenga un menor en su casa o bajo su supervisión debería implementar protecciones básicas de control parental, incluyendo la configuración del hardware y dispositivos del ordenador, la instalación de software o servicios de filtrado de proveedores de servicios de Internet, para bloquear el acceso de sus menores a los contenidos de la plataforma. Sé responsable, conoce lo que hacen tus hijos en Internet. Los usuarios también deben implementar protecciones de control parental (hardware de PC, software o servicios de filtrado) para ayudar a limitar el acceso de los menores a material dañino. Tenemos una política de tolerancia cero para el material pornográfico que involucra a menores y con respecto a los pedófilos o cualquier actividad pedófila.</p>

<p>13.7. Los enlaces de interés para proteger a los menores y establecer control parental en los terminales son los siguientes:</p>

<p><a href="https://www.asacp.org/" target="_blank" rel="noreferrer noopener">ASACP</a>&nbsp;– Organización sin ánimo de lucro dedicada a la protección infantil en línea, que lucha contra la explotación infantil a través de su CE Reporting Tipline y ayuda a los padres a evitar que los niños vean material restringido por edad en línea con la etiqueta del sitio web Restricted To Adults – RTA.</p>

<p><a href="https://www.rtalabel.org/index.php?t=.es" target="_blank" rel="noreferrer noopener">RTA LABEL</a>&nbsp;– Nuestro sitio web está etiquetado con este sistema de tal manera que cualquier padre puede restringir el acceso a nuestra web a través del software especifico distribuido por esta empresa. Más información&nbsp;<a href="https://www.rtalabel.org/index.php?content=parents" target="_blank" rel="noreferrer noopener">aquí</a>&nbsp;y&nbsp;aquí.</p>

<p>Protege a tus hijos del contenido para adultos y bloquea el acceso a este sitio utilizando el control parental. Utilizamos la etiqueta de sitio web «Restringido a adultos» (RTA) para permitir mejor el filtrado parental. Las herramientas parentales compatibles con la etiqueta RTA bloquearán el acceso a este sitio.</p>

<p>Con el fin de ayudar a restringir el acceso a menores, nos hemos asegurado de que&nbsp;www.viptoenjoy.com&nbsp;cumpla, y siga siendo, totalmente compatible con RTA [Restringido para adultos], y permita que todas las páginas sean bloqueadas por simples herramientas de control parental.</p>

<p>13.8. Otras herramientas importantes para proteger a los niños del contenido para adultos de manera que en los motores de búsqueda se bloquee el contenido para adultos en los resultados de búsqueda, son los siguientes:</p>

<ul>
<li><a href="https://support.google.com/websearch/answer/510" target="_blank" rel="noreferrer noopener">Google Safe Search</a>&nbsp;se puede activar para: cuentas o navegadores personales, dispositivos supervisados para niños y cuentas que usan la aplicación Family Link, dispositivos y redes del lugar de trabajo o de la escuela. Le recomendamos que active SafeSearch en los dispositivos de todos los menores a su cargo.</li>
<li><a href="https://safety.yahoo.com/SafetyGuides/Search/index.htm" target="_blank" rel="noreferrer noopener">Yahoo SafeSearch</a>&nbsp;ayudará a bloquear contenido explícito en los resultados de búsqueda de Yahoo.</li>
<li><a href="https://www.bing.com/account/general?ru=https%3a%2f%2fwww.bing.com%2f&amp;FORM=O2HV46&amp;sh=0" target="_blank" rel="noreferrer noopener">Microsoft SafeSearch</a>&nbsp;hará lo mismo con esos resultados de búsqueda en Bing.</li>
<li><a href="https://yandex.com/support/search/additional-features/adult-filter.html" target="_blank" rel="noreferrer noopener">Yandex Family Search</a>&nbsp;excluirá el contenido no deseado de los resultados de búsqueda en Yandex.</li>
</ul>

<p>También recomendamos instalar, predeterminar o exigir motores de búsqueda específicos por edad en los dispositivos de sus hijos. Están especialmente diseñados para ayudar a examinar el contenido web, como palabras, imágenes y videos. Además, son muy fáciles de usar y mucho más visuales. Estas diferencias ayudan a los niños a encontrar lo que buscan sin llegar a páginas que puedan resultar inapropiadas:&nbsp;<a href="https://www.kiddle.co/" target="_blank" rel="noreferrer noopener">Kiddle</a>,&nbsp;<a href="https://www.alarms.org/kidrex/" target="_blank" rel="noreferrer noopener">KidRex</a>,&nbsp;<a href="https://wackysafe.com/" target="_blank" rel="noreferrer noopener">WackySafe</a>,&nbsp;<a href="https://kidoz.net/" target="_blank" rel="noreferrer noopener">Kido’z</a>.</p>

<p>13.9. Todos los sistemas operativos principales (Windows de Microsoft, Mac OS de Apple, Android y la plataforma de Amazon) ofrecen configuraciones para evitar que los niños accedan a cosas que no quieres que vean.</p>

<p>Apple (iOS)</p>

<p>iPhone, iPad, Mac, Apple Watch y Apple TV. Los dispositivos Apple tienen controles parentales similares que se pueden habilitar siguiendo las instrucciones en el sitio dedicado para familias de Apple.Puede configurar los dispositivos de sus hijos para limitar el contenido para adultos o abrir solo los sitios web que seleccione. . También puede instalar navegadores web especiales que están diseñados para mostrar contenido apto para niños y nada más..</p>

<p>–&nbsp;<a href="https://support.apple.com/en-us/HT201304" target="_blank" rel="noreferrer noopener">Use los controles parentales en el iPhone, iPad y iPod touch de su hijo</a><br>–&nbsp;<a href="https://support.apple.com/en-gb/guide/mac-help/welcome/mac" target="_blank" rel="noreferrer noopener">Más información sobre los controles parentales en macOS</a></p>

<p>Android</p>

<p>Los productos Android, como los teléfonos inteligentes y las tabletas, contienen protecciones similares, lo que permite a los padres elegir lo que sus hijos pueden ver y hacer en sus dispositivos personales. El&nbsp;<a href="https://safety.google/families/" target="_blank" rel="noreferrer noopener">Centro de seguridad de Google</a>&nbsp;lo guiará a través del proceso de configuración.</p>

<p>Microsoft</p>

<p>Windows 10, de forma predeterminada, ofrece opciones para familias y padres para garantizar que los niños estén protegidos cuando están en línea. Para activar los controles parentales para su hijo, vaya a la barra de búsqueda de Windows, escriba «opciones familiares» y elija las opciones en la configuración. Cree una cuenta para su hijo y habilite los controles parentales.&nbsp;<a href="https://account.microsoft.com/family/about?ru=https:%2F%2Faccount.microsoft.com%2Ffamily" target="_blank" rel="noreferrer noopener">Más información aquí</a>.</p>

<p>Amazon Kids+ (anteriormente FreeTime Unlimited)</p>

<p>Los padres reciben acceso a&nbsp;<a href="https://www.amazon.com/ftu/home" target="_blank" rel="noreferrer noopener">controles parentales fáciles de usar</a>&nbsp;que les permiten encontrar el equilibrio adecuado entre educación y entretenimiento. Los padres pueden personalizar los límites de tiempo frente a la pantalla, establecer objetivos educativos, filtrar el contenido apropiado para la edad y también administrar la navegación web y el uso del contenido según sus preferencias.</p>

<p>13.10. El software de control parental puede bloquear de manera efectiva la mayoría del contenido para adultos, especialmente sitios como&nbsp;<a href="http://www.destacamos.com/">www.destacamos.com</a>. Diferentes softwares ofrecen diferentes soluciones, pero algunas de las opciones más populares se enumeran a continuación.</p>

<ol>
<li><a href="https://www.qustodio.com/" target="_blank" rel="noreferrer noopener">Qustodio</a></li>
<li><a href="https://www.netnanny.com/" target="_blank" rel="noreferrer noopener">Net Nanny</a></li>
<li><a href="https://family.norton.com/" target="_blank" rel="noreferrer noopener">Norton Family</a></li>
<li><a href="https://www.mobicip.com/" target="_blank" rel="noreferrer noopener">Mobicip</a></li>
<li><a href="https://www.sentrypc.com/" target="_blank" rel="noreferrer noopener">SentryPC</a></li>
<li><a href="https://www.bark.us/" target="_blank" rel="noreferrer noopener">Bark</a></li>
</ol>

<p>13.11. Tanto Google Play como Apple App Store son ya en cierto modo aptas para niños, dado que no permiten la existencia de ningún tipo de aplicación con contenido adulto. Aun así, si quisiera podría supervisar también las descargas de aplicaciones:</p>

<p><a href="http://www.techradar.com/news/mobile-computing/tablets/how-to-make-android-child-friendly-1045708" target="_blank" rel="noreferrer noopener">http://www.techradar.com/news/mobile-computing/tablets/how-to-make-android-child-friendly-1045708</a></p>

<p>Además, su proveedor de servicios de internet puede ofrecerle soluciones para filtrar su contenido de conexión a Internet. Póngase en contacto con ellos para saber la manera de implementarlos en sus dispositivos.</p>

<p>Por último, si se desea obtener más información sobre cómo proteger a los hijos menores en línea, se pueden consultar las siguientes páginas web:</p>

<ol>
<li><a href="https://www.fosi.org/" target="_blank" rel="noreferrer noopener">https://www.fosi.org/</a></li>
<li><a href="https://digitalparenting.com/" target="_blank" rel="noreferrer noopener">https://digitalparenting.com/</a></li>
<li><a href="https://www.connectsafely.org/" target="_blank" rel="noreferrer noopener">https://www.connectsafely.org/</a></li>
<li><a href="https://www.betterinternetforkids.eu/" target="_blank" rel="noreferrer noopener">https://www.betterinternetforkids.eu/</a></li>
<li><a href="https://www.internetmatters.org/" target="_blank" rel="noreferrer noopener">https://www.internetmatters.org/</a></li>
<li><a href="https://icac.scag.gov/digital-parenting/" target="_blank" rel="noreferrer noopener">https://icac.scag.gov/digital-parenting/</a></li>
<li><a href="https://www.saferinternet.org.uk/" target="_blank" rel="noreferrer noopener">https://www.saferinternet.org.uk/</a></li>
</ol>

<p><strong>14. JURISDICCIÓN</strong></p>

<p>Las relaciones establecidas entre El titular de la plataforma&nbsp;www.viptoenjoy.com&nbsp;y el los/las profesionales, clientes y usuarios finales se regirán por lo dispuesto en la normativa vigente acerca de la legislación aplicable y la jurisdicción competente. No obstante, para los casos en los que la normativa prevea la posibilidad a las partes de someterse a un fuero, El titular de la plataforma&nbsp;www.viptoenjoy.com&nbsp;y el los/las profesionales y usuarios finales, renuncian expresa a cualquier otro fuero que pudiera corresponderles, y se someten a los Juzgados y Tribunales de Mallorca.</p>
`,
};

export default LegalLegalNotice;
