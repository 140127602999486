export const LegalTermsOfService = {
  title: "CONDICIONES DE CONTRATACIÓN DE SERVICIOS",
  content: `  
  <p class="has-small-font-size">La contratación del servicio “renovación automática” vinculado a un anuncio publicado en el Portal sólo producirá efectos DURANTE el periodo de vigencia del anuncio, no suponiendo en ningún caso una prórroga en la vigencia del mismo por finalización de su publicación, ni tampoco por eliminación activa del anuncio.</p>
  
  <p>1. <strong>CONTRATACIÓN CON TERCEROS A TRAVÉS DEL PORTAL</strong></p>

  <p class="has-small-font-size">El Usuario reconoce y acepta que cualquier relación contractual o extracontractual que formalice con los anunciantes o terceras personas contactadas a través del Portal, así como su participación en concursos, promociones, compraventa de bienes o servicios, se entienden realizados única y exclusivamente entre el Usuario y el anunciante y/o tercera persona. El Usuario acepta por lo tanto que  ViptoEnjoy.com no tiene ningún tipo de responsabilidad sobre los daños o perjuicios de cualquier naturaleza ocasionados con motivo de sus negociaciones, conversaciones y/o relaciones contractuales o extracontractuales con los anunciantes o terceras personas físicas o jurídicas contactadas a través del Portal.</p>
  
  <p>2. <strong>ENLACES DE TEXTO Y ENLACES GRÁFICOS</strong></p>
  
  <p class="has-small-font-size">El Portal pone a disposición de sus clientes dispositivos técnicos de enlace de texto y/o enlaces gráficos que permiten a los usuarios el acceso a páginas web titularidad de otras entidades. Estos enlaces están debidamente señalados como publicidad en el Portal.</p>
  
  <p class="has-small-font-size">El cliente previamente autorizado reconoce y acepta que la utilización de los contenidos de las páginas web enlazadas será bajo su exclusivo riesgo y responsabilidad y exonera a VipToEnjoy, S.L. de cualquier responsabilidad sobre disponibilidad técnica de las páginas web enlazadas, la calidad, fiabilidad, exactitud y/o veracidad de los servicios, informaciones, elementos y/o contenidos a los que el usuario pueda acceder en las mismas.</p>
  
  <p class="has-small-font-size">ViptoEnjoy.com no será responsable indirecta ni subsidiariamente de los daños y perjuicios de cualquier naturaleza derivados de <strong>a)</strong> el funcionamiento, indisponibilidad, inaccesibilidad y la ausencia de continuidad de las páginas web enlazadas; <strong>b)</strong> la falta de mantenimiento y actualización de los contenidos y servicios contenidos en las páginas web enlazadas; <strong>c) </strong>la falta de calidad, inexactitud, ilicitud, inutilidad de los contenidos y servicios de las páginas web enlazadas.</p>
  
  <p>3. <strong>DERECHO DE LIMITAR O PONER FIN AL SERVICIO DE ViptoEnjoy.COM</strong></p>
  
  <p class="has-small-font-size">ViptoEnjoy.com se reserva el derecho, ejercitable en cualquier momento y de modo discrecional a rechazar cualquier anuncio o compromiso de ubicación de un anuncio en una categoría o localidad determinada.  ViptoEnjoy.com también se reserva el derecho de eliminar cualquier anuncio del Portal sin necesidad de avisar previamente a los usuarios y/o anunciantes.</p>
  
  <p class="has-small-font-size">ViptoEnjoy.com puede denegar o poner fin a su servicio y adoptar medidas técnicas y legales para mantener a los usuarios alejados del Portal si creemos que están creando problemas o actuando de forma contraria al espíritu o la forma de nuestras normas y condiciones de uso, todo ello con independencia de cualquier pago realizado por el uso del Portal o servicios complementarios. Sin embargo, decidamos o no retirar el acceso al sitio web de un usuario, no aceptamos ninguna responsabilidad por el uso no autorizado o ilegal del sitio web por los usuarios, tal y como se describe en los párrafos anteriores.</p>
  
  <p>4. <strong>INDEMNIZACIÓN POR USO ABUSIVO</strong></p>

  <p class="has-small-font-size">ViptoEnjoy.com se reserva el derecho, ejercitable en cualquier momento a cobrar una indemnización de un euro o su equivalente en créditos por cada anuncio que se vea obligada a eliminar del portal como consecuencia de contravenir las presentes Condiciones de Uso o Normas de publicación. Tanto el anunciante final como terceras personas o empresas que faciliten con su ayuda el uso abusivo de los servicios, podrán ser requeridos para el pago de esta indemnización.</p>
  
  <p>5. <strong>VARIOS</strong></p>
  
  <ul>
  <li><strong>Modificaciones en el Servicio y Condiciones de Uso</strong></li>
  </ul>
  
  <p class="has-small-font-size">ViptoEnjoy.com se reserva el derecho a realizar cambios en el Portal, pudiendo modificar, suprimir e incluir, unilateralmente y sin previo aviso, nuevos contenidos así como la forma en que estos aparezcan presentados y localizados.</p>
  
  <p class="has-small-font-size">Asimismo,  ViptoEnjoy.com se reserva el derecho a realizar cambios las presentes Condiciones de Uso en cualquier momento. El usuario quedará sujeto a las nuevas Condiciones de Uso que hayan sido publicadas en el momento en que acceda o utilice los servicios del Portal.</p>
  
  <p class="has-small-font-size">Si alguna de las presentes condiciones resulta invalidada, nula o inaplicable por cualquier motivo, dicha condición quedará excluida y no afectará a la validez ni la aplicabilidad del resto de condiciones.</p>
  
  <ul>
  <li><strong>Precios de los Servicios</strong></li>
  </ul>
  
  <p class="has-small-font-size">Los precios de los servicios serán establecidos en las correspondientes páginas del Portal para cada servicio y serán válidos durante el tiempo que los mismos permanezcan accesibles al Usuario.</p>
  
  <ul>
  <li><strong>Menores de Edad</strong></li>
  </ul>
  
  <p class="has-small-font-size">El acceso a la página está restringido exclusivamente a mayores de 18 años. Serán los padres, tutores o representantes legales, quienes serán responsables de todos los actos realizados a través del Portal por los menores a su cargo.</p>
  
  <p>6. <strong>DURACIÓN Y TERMINACIÓN</strong></p>
  
  <p class="has-small-font-size">La prestación de los servicios y/o contenidos del Portal tiene una duración indefinida.</p>
  
  <p class="has-small-font-size">Sin perjuicio de lo anterior, y además de por las causas establecidas legalmente,  ViptoEnjoy.com está facultada para dar por terminada, suspender o interrumpir unilateralmente, en cualquier momento y sin necesidad de preaviso, la prestación del servicio y del Portal y/o cualquiera de los servicios.</p>
  
  <p>7. <strong>JURISDICCIÓN</strong></p>
  
  <p class="has-small-font-size">La ley aplicable en caso de disputa o conflicto de interpretación de los términos que conforman estas Condiciones de Uso, así como cualquier cuestión relacionada con los servicios del Portal, será la ley española.</p>
  
  <p class="has-small-font-size">Para la resolución de cualquier controversia que pudiera surgir con ocasión del uso del Portal y sus servicios, las partes acuerdan someterse a la jurisdicción de los Juzgados y Tribunales de la ciudad de Madrid (España), y sus superiores jerárquicos, con expresa renuncia a otros fueros si lo tuvieren y fueran diferentes de los reseñados.</p>
`,
};

export default LegalTermsOfService;
