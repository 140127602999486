import React, { useContext, useState } from "react";
import {
  Container,
  Table,
  TableCell,
  Segment,
  Button,
} from "semantic-ui-react";
import { PathRoutes, Texts } from "../../data/Data";
import { useManager, useManagerUpdate } from "../../data/api/useManager";
import AccountForm from "../../components/AccountForm";
import AccountManagerForm from "../../data/forms/AccountManagerForm";
import { Link } from "react-router-dom";
import { sessionContext } from "../../App";
import _ from "lodash/fp";
import MessageModal from "../../components/MessageModal";

const AccountManager = () => {
  const managerData = useContext(sessionContext);
  const id = managerData.alias;
  const { manager, channels } = useManager(id);
  const { trigger } = useManagerUpdate(manager.id);
  const [open, setOpen] = useState(false);

  const handleOnSubmit = (values) => {
    trigger(values).then((response) => {
      setOpen(true);
    });
  };

  return (
    <Container className="account-manager">
      <h2>{Texts.account.manager.title}</h2>
      <Segment className="account-manager-info">
        <h3>{Texts.account.manager.info.title}</h3>
        <AccountForm
          name="account-manager"
          formFields={AccountManagerForm}
          values={manager}
          onSubmit={handleOnSubmit}
          texts={Texts.account.manager.info}
        />
      </Segment>
      <MessageModal
        open={open}
        texts={Texts.account.manager.messages.success}
        action={() => window.location.reload()}
        onClose={() => window.location.reload()}
      />
      <Segment className="account-manager-channels">
        <h3>{Texts.account.manager.channels.title}</h3>
        <div className="account-manager-channels-table">
          <Button primary href={PathRoutes.PATH_ACCOUNT_CHANNEL_NEW}>
            {Texts.account.manager.channels.new}
          </Button>
          <Table>
            <Table.Header>
              <Table.Row>
                <TableCell>
                  {Texts.account.manager.channels.tableHeaders.channel}
                </TableCell>
                <TableCell>
                  {Texts.account.manager.channels.tableHeaders.premium}
                </TableCell>
                <TableCell>
                  {Texts.account.manager.channels.tableHeaders.last_update}
                </TableCell>
                <TableCell>
                  {Texts.account.manager.channels.tableHeaders.favs}
                </TableCell>
                <TableCell>
                  {Texts.account.manager.channels.tableHeaders.subs}
                </TableCell>
                <TableCell></TableCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(
                ({
                  slug,
                  total_follows,
                  total_subs,
                  fecha_alta,
                  ultima_modificacion,
                }) => {
                  const date = new Date(ultima_modificacion || fecha_alta);
                  const options = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  };
                  return (
                    <Table.Row>
                      <TableCell>{slug}</TableCell>
                      <TableCell>No</TableCell>
                      <TableCell>
                        {date.toLocaleDateString(navigator.language, options)}
                      </TableCell>
                      <TableCell>{total_follows}</TableCell>
                      <TableCell>{total_subs}</TableCell>
                      <TableCell>
                        <Link to={`${PathRoutes.PATH_ACCOUNT_MANAGER}/${slug}`}>
                          <Button primary>
                            {Texts.account.manager.channels.talbeBody.edit}
                          </Button>
                        </Link>
                      </TableCell>
                    </Table.Row>
                  );
                }
              )(channels)}
            </Table.Body>
          </Table>
        </div>
      </Segment>
      <Segment className="account-manager-wallet">
        <h3>{Texts.account.manager.wallet.title}</h3>
        <Button primary as={Link} to={PathRoutes.PATH_CREDITS}>
          {Texts.account.user.wallet.buy}
        </Button>
        <Table>
          <Table.Header>
            <Table.Row>
              <TableCell>
                {Texts.account.manager.wallet.tableHeaders.date}
              </TableCell>
              <TableCell>
                {Texts.account.manager.wallet.tableHeaders.for}
              </TableCell>
              <TableCell>
                {Texts.account.manager.wallet.tableHeaders.used}
              </TableCell>
              <TableCell>
                {Texts.account.manager.wallet.tableHeaders.total}
              </TableCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <TableCell>25 octubre 2023</TableCell>
              <TableCell>Renovación anuncio Canal1</TableCell>
              <TableCell>-1 crédito</TableCell>
              <TableCell>3</TableCell>
            </Table.Row>
            <Table.Row>
              <TableCell>20 octubre 2023</TableCell>
              <TableCell>Renovación anuncio Canal2</TableCell>
              <TableCell>-1 crédito</TableCell>
              <TableCell>4</TableCell>
            </Table.Row>
            <Table.Row>
              <TableCell>5 octubre 2023</TableCell>
              <TableCell>Compra de créditos 5</TableCell>
              <TableCell>+5 créditos (VLVL22019ASDD987)</TableCell>
              <TableCell>5</TableCell>
            </Table.Row>
            <Table.Row>
              <TableCell>20 octubre 2023</TableCell>
              <TableCell>Renovación anuncio Canal1</TableCell>
              <TableCell>-1 crédito</TableCell>
              <TableCell>0 créditos</TableCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    </Container>
  );
};

export default AccountManager;
