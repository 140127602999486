export const About = {
  title: "Quienes somos",
  subtitle: "Somos creadores y fiel creyentes <br />de la liberación sexual.",
  paragraphs: [
    "Para nuestro equipo quedo atrás, el porno lleno de opacidades, exageraciones, basado en elementos fantasiosos, un porno de mentiras con sexo irreal y, sobre todo, poco disfrute.",
    "Webs, redes, espectáculos, festivales eróticos, super estrellas del porno en total sobreactuación y egocentrismo.",
    "Un mundo antiguo y retrógrado. Vacío de moralidad y desfasado de toda naturalidad y realidad. No es que todas estas formas que conocemos de diversión no funcionen.Pero, bajo nuestro criterio, ya no son suficientes.",
    "Más para quienes buscan realidades y disfrutar verdaderamente de sus placeres.Sabíamos que no se trataba solo de nosotros. Hoy en día, el sexo y el porno es una realidad, se ha democratizado la naturalidad de querer disfrutar viviendo y creando adulto totalmente transparente.",
    "Cuando hay conocimientos y hay profesionalismo, se disfruta a otro nivel. Así que creamos un espacio para los más elevados. Para quienes buscaban su propio Nirvana de placer real.  Para quienes consumen porno, compran productos eróticos y quieren cada vez contenido más natural, más bello, más divertido, en definitiva: otro nivel de entretenimiento adulto.",
    "Más enfocado en el disfrute y el verdadero placer, y menos fake.",
    "VivaLaVidaLoca es un universo lúdico de entretenimiento para adultos.<br />El espacio para quienes quieren libertad.",
  ],
};

export default About;
