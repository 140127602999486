export const LegalCookiePolicy = {
  title: "POLITICA DE COOKIES DE VIPTOENJOY.COM",
  content: `
  <div class="entry-content wp-block-post-content is-layout-constrained wp-block-post-content-is-layout-constrained">
  <p>Viptoenjoy, S.L. , en aplicación del artículo 22 de la&nbsp;<em>Ley 34/2022, de 11 de julio de Servicios de la Sociedad de la Información y de Comercio Electrónico,</em>&nbsp;informa y expone en el presente documento su Política de Cookies.</p>
  
  <p>La finalidad de las cookies es ofrecer al usuario una navegación óptima, así como mejorar todos los servicios que se le ofrecen en función de los usos que se les proporcionen a los contenidos que se publican en la plataforma&nbsp;<a href="http://www.viptoenjoy.com">www.viptoenjoy.com</a>. Además, la funcionalidad de las cookies integradas en&nbsp;<a href="http://www.viptoenjoy.com"></a><a href="http://www.destacamos.com/">www.viptoenjoy.com</a>&nbsp;es efectuar un seguimiento sobre los servicios consultados y/o revisar los accesos que se hagan por los usuarios a los diferentes apartados de la plataforma de servicios publicitarios.</p>
  
  <p><strong>1. DEFINICIÓN Y TIPOS DE COOKIES</strong></p>
  
  <p>Una cookie es un fichero que se descarga y almacena en el equipo del usuario al acceder a determinadas páginas web, para almacenar y recuperar información sobre los hábitos de navegación de un usuario o que se realiza desde su equipo.</p>
  
  <p>Las cookies son fundamentales para el funcionamiento de Internet, al aportar beneficios durante la navegación y para la prestación de servicios interactivos. Asimismo, debe advertirse que las cookies no pueden dañar su equipo y que, en contraprestación, al estar activadas facilitan la identificación y resolución de errores.</p>
  
  <p>Existen diferentes tipos de cookies, que se podrían agrupar bajo la siguiente clasificación:</p>
  
  <p><strong>1.1. Según la entidad gestora:</strong></p>
  
  <p>Según quien sea la entidad que gestione el equipo o dominio desde donde se envían las cookies y trate los datos que se obtengan, podemos distinguir:</p>
  
  <ul>
  <li><strong>Cookies propias</strong>: Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.</li>
  </ul>
  
  <ul>
  <li><strong>Cookies de terceros</strong>: Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.</li>
  </ul>
  
  <p>En el caso de que las cookies sean instaladas desde un equipo o dominio gestionado por el propio editor pero la información que se recoja mediante éstas sea gestionada por un tercero, no pueden ser consideradas como cookies propias.</p>
  
  <p><strong>1.2. Según su duración activadas:</strong></p>
  
  <p>Según el plazo de tiempo que permanecen activadas en el equipo terminal podemos distinguir:</p>
  
  <ul>
  <li><strong>Cookies de sesión</strong>: Son aquellas diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una sola ocasión.</li>
  </ul>
  
  <ul>
  <li><strong>Cookies persistentes</strong>: Son aquellas en las que los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.</li>
  </ul>
  
  <p><strong>1.3. Según su finalidad:</strong></p>
  
  <p>Según la finalidad para la que se traten los datos obtenidos a través de las cookies, podemos distinguir entre:</p>
  
  <ul>
  <li><strong>Cookies técnicas</strong>: Son aquéllas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de vídeos o sonido o compartir contenidos a través de redes sociales.</li>
  </ul>
  
  <ul>
  <li><strong>Cookies de personalización</strong>: Son aquéllas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.</li>
  </ul>
  
  <ul>
  <li><strong>Cookies de análisis</strong>: Son aquéllas que permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.</li>
  </ul>
  
  <ul>
  <li><strong>Cookies publicitarias</strong>: Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en la página web, aplicación o plataforma desde la que presta el servicio solicitado en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.</li>
  </ul>
  
  <ul>
  <li><strong>Cookies de publicidad comportamental</strong>: Son aquéllas que permiten la gestión de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan información de comportamiento de los usuarios obtenida a través de la observación de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.</li>
  </ul>
  
  <p><strong>2. COOKIES USADAS EN WWW.VIPTOENJOY.COM</strong></p>
  
  <p>Las cookies utilizadas en la página Web&nbsp;www.viptoenjoy.com&nbsp;son las siguientes:</p>
  
  <p><strong>Cookies de sesión</strong>: Su función es registrar&nbsp;datos sobre la manera en que los navegantes interactúan con un sitio&nbsp;<em>web</em>. La idea que se esconde detrás de su uso es mejorar la experiencia de forma continua gracias a un mejor conocimiento de las incidencias más habituales o qué páginas generan mayores conflictos.</p>
  
  <p><strong>Cookies de autenticación</strong>: Viptoenjoy utiliza este tipo de cookies siempre que se entra en la plataforma y en el caso de que te hayas registrado podrá recordar tu contraseña si lo deseas, confirmando su identidad.</p>
  
  <p><strong>Cookies de técnicas y de análisis</strong>: para ayudar a mejorar la calidad de nuestro servicio, incluyendo almacenar preferencias del usuario, mejorando la selección de perfiles que se le presentan, los resultados de búsqueda, así como rastrear tendencias de usuario. Con ellas Viptoenjoy supervisa el tráfico de su plataforma y extrae datos anonimizados en términos de comunicación de datos. Además, sirven para conocer el comportamiento de los usuarios y establecer un perfil del visitante.</p>
  
  <p><strong>Cookies de preferencia</strong>: Viptoenjoy las utiliza para recordar información y de esta forma, cada vez que el usuario acceda al servicio con determinadas características que pueden diferenciar su experiencia de la de otros usuarios, como, por ejemplo, la provincia desde la que te registras o la categoría predeterminada de servicios publicitarios que has seleccionado.</p>
  
  <p><strong>Cookies de seguridad</strong>: Viptoenjoy utiliza estas cookies para autentificar a sus usuarios y evitar el uso fraudulento de las credenciales por parte de terceros. Además, con su uso se protege la información de nuestros usuarios.&nbsp;</p>
  
  <p>La lista de cookies utilizadas podrá modificarse en función de la&nbsp;incorporación o exclusión de servicios en las webs y aplicaciones.</p>
  
  <p><strong>Tipos de cookies por vencimiento</strong>.</p>
  
  <p>Las Cookies utilizadas en Viptoenjoy pueden tener los siguientes tipos de vencimientos:</p>
  
  <p>a.&nbsp;<strong>De sesión</strong>: Con vencimiento una vez que el usuario sale de la sesión.</p>
  
  <p>b.&nbsp;<strong>De Servicio</strong>&nbsp;Relacionadas con algún servicio personalizado dentro del site, con vencimiento inferior a veinticuatro horas (24h).</p>
  
  <p>c.&nbsp;<strong>Persistentes</strong>: quedan permanentemente instaladas en tu navegador y son activadas cada vez que visitas el site, siempre y cuando no se desactive su uso.</p>
  
  <p><br><strong>Configuración de cookies</strong></p>
  
  <p>Para conocer la forma en la que configurar la gestión de cookies en su navegador puede consultar los siguientes links de los principales navegadores y dispositivos:</p>
  
  <p>Para&nbsp;<strong>Internet Explorer</strong>™ :<br><a href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies">http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies</a></p>
  
  <p>Para&nbsp;<strong>Safari</strong>™ :<br><a href="http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html">http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html</a></p>
  
  <p>Para&nbsp;<strong>Chrome</strong>™ :<br><a href="http://support.google.com/chrome/bin/answer.py?hl=fr&amp;hlrm=en&amp;answer=95647">http://support.google.com/chrome/bin/answer.py?hl=fr&amp;hlrm=en&amp;answer=95647</a></p>
  
  <p>Para&nbsp;<strong>Firefox</strong>™:<br><a href="http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies">http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies</a></p>
  
  <p>Para&nbsp;<strong>Opera</strong>™ :<br><a href="http://help.opera.com/Windows/10.20/fr/cookies.html">http://help.opera.com/Windows/10.20/fr/cookies.html</a></p>
  
  <p>Para&nbsp;<strong>Android</strong><br><a href="http://support.google.com/android/?hl=en">http://support.google.com/android/?hl=en</a></p>
  
  <p>Para&nbsp;<strong>Windows Phone</strong></p>
  
  <p><a href="http://www.microsoft.com/windowsphone/en-us/howto/wp7/web/changingprivacy-and-other-browser-settings.aspx">http://www.microsoft.com/windowsphone/en-us/howto/wp7/web/changingprivacy-and-other-browser-settings.aspx</a></p>
  
  <p>Para&nbsp;<strong>Blackberry</strong>&nbsp;<a href="http://docs.blackberry.com/en/smartphone_users/deliverables/32004/Turn_off_cookies_in_the_browser_60_1072866_11.jsp">http://docs.blackberry.com/en/smartphone_users/deliverables/32004/Turn_off_cookies_in_the_browser_60_1072866_11.jsp</a></p>
  
  <p><strong>3. REVOCACIÓN Y ELIMINACIÓN DE COOKIES</strong></p>
  
  <p>Puedes permitir, bloquear o eliminar las cookies instaladas en tu ordenador mediante la configuración de las opciones del navegador instalado en tu ordenador.</p>
  
  <p><strong>4. CONFIGURACIÓN DE COOKIES PARA LOS NAVEGADORES MÁS POPULARES.</strong></p>
  
  <p>A continuación, le indicamos cómo acceder a una&nbsp;<em>cookie</em>&nbsp;determinada del navegador&nbsp;<strong>Chrome</strong>. Nota: estos pasos pueden variar en función de la versión del navegador:</p>
  
  <ol start="1">
  <li>Vaya a Configuración o Preferencias mediante el menú Archivo o bien pinchando el icono de personalización que aparece arriba a la derecha.</li>
  
  <li>Verá diferentes secciones, pinche la opción Mostrar opciones avanzadas.</li>
  
  <li>Vaya a Privacidad, Configuración de contenido.</li>
  
  <li>Seleccione Todas las cookies y los datos de sitios.</li>
  
  <li>Aparecerá un listado con todas las cookies ordenadas por dominio. Para que le sea más fácil encontrar las cookies de un determinado dominio introduzca parcial o totalmente la dirección en el campo Buscar cookies.</li>
  
  <li>Tras realizar este filtro aparecerán en pantalla una o varias líneas con las cookies de la web solicitada. Ahora sólo tiene que seleccionarla y pulsar la X para proceder a su eliminación.</li>
  </ol>
  
  <p>Para acceder a la configuración de cookies del navegador&nbsp;<strong>Internet Explorer</strong>&nbsp;siga estos pasos (pueden variar en función de la versión del navegador):</p>
  
  <ol start="1">
  <li>Vaya a Herramientas, Opciones de Internet</li>
  
  <li>Haga click en Privacidad.</li>
  
  <li>Mueva el deslizador hasta ajustar el nivel de privacidad que desee.</li>
  </ol>
  
  <p>Para acceder a la configuración de cookies del navegador&nbsp;<strong>Firefox</strong>&nbsp;siga estos pasos (pueden variar en función de la versión del navegador):</p>
  
  <ol start="1">
  <li>Vaya a Opciones o Preferencias según su sistema operativo.</li>
  
  <li>Haga click en Privacidad.</li>
  
  <li>En Historial elija Usar una configuración personalizada para el historial.</li>
  
  <li>Ahora verá la opción Aceptar cookies, puede activarla o desactivarla según sus preferencias.</li>
  </ol>
  
  <p>Para acceder a la configuración de cookies del navegador&nbsp;<strong>Safari para OSX</strong>&nbsp;siga estos pasos (pueden variar en función de la versión del navegador):</p>
  
  <ol start="1">
  <li>Vaya a Preferencias, luego Privacidad.</li>
  
  <li>En este lugar verá la opción Bloquear cookies para que ajuste el tipo de bloqueo que desea realizar.</li>
  </ol>
  
  <p>Para acceder a la configuración de cookies del navegador&nbsp;<strong>Safari para iOS</strong>&nbsp;siga estos pasos (pueden variar en función de la versión del navegador):</p>
  
  <ol start="1">
  <li>Vaya a Ajustes, luego Safari.</li>
  
  <li>Vaya a Privacidad y Seguridad, verá la opción Bloquear cookies para que ajuste el tipo de bloqueo que desea realizar.</li>
  </ol>
  
  <p>Para acceder a la configuración de cookies del navegador para dispositivos&nbsp;<strong>Android</strong>&nbsp;siga estos pasos (pueden variar en función de la versión del navegador):</p>
  
  <ol start="1">
  <li>Ejecute el navegador y pulse la tecla Menú, luego Ajustes.</li>
  
  <li>Vaya a Seguridad y Privacidad, verá la opción Aceptar cookies para que active o desactive la casilla.</li>
  </ol>
  
  <p>Para acceder a la configuración de cookies del navegador para dispositivos&nbsp;<strong>Windows Phone</strong>&nbsp;siga estos pasos (pueden variar en función de la versión del navegador):</p>
  
  <ol start="1">
  <li>Abra Internet Explorer, luego Más, luego Configuración</li>
  
  <li>Ahora puede activar o desactivar la casilla Permitir cookies.</li>
  </ol>
  
  <p><strong>5. LA ACEPTACIÓN DE LAS COOKIES EN ESTA PLATAFORMA DE SERVICIOS PUBLICITARIOS.</strong></p>
  
  <p>En relación con las cookies, La&nbsp;<em>Ley 34/2002, de 11 de julio, de la sociedad de la información y comercio electrónico</em>&nbsp;exige que los usuarios estén informados con carácter previo a la navegación por cualquier página web, sobre el tipo, uso y finalidad de las cookies utilizadas.</p>
  
  <p>Para cumplir la anterior normativa, Viptoenjoy ha incluido la presente Política de Privacidad sobre las cookies que se despliegan cada vez que el usuario accede a la plataforma de servicios publicitarios, informándole con carácter previo de la utilización de cookies.</p>
  
  <p>Viptoenjoy le informa como usuario de nuestra plataforma de servicios publicitarios que, si omite la aceptación del uso de las cookies en el aviso inicial y continúa navegando por la plataforma, se presumirá que existe un consentimiento tácito de su parte para la utilización de las cookies descritas en el apartado anterior, cumpliendo con la normativa de la LSSI.</p>
  
  <p>Viptoenjoy no se hace responsable del contenido y veracidad de las Políticas de Privacidad de los terceros incluidas en esta Política de cookies.</p>
  
  <p>Para cualquier consulta sobre esta Política de cookies puede dirigirse a&nbsp;<a href="mailto:info@viptoenjoy.com">info@viptoenjoy.com</a>.</p>
  </div>
`,
};

export default LegalCookiePolicy;
