import React from "react";
import { Texts } from "../data/Texts";
import { Button } from "semantic-ui-react";
import { useCookies } from "react-cookie";
import _ from "lodash/fp";

export const Agegate = () => {
  const [cookies, setCookie] = useCookies(["agegate-acceptance"]);

  return (
    <>
      {!_.get("agegate-acceptance")(cookies) ? (
        <div className="agegate">
          <div className="agegate-container">
            <h3 className="agegate-container-title">{Texts.agegate.title}</h3>
            <p className="agegate-container-message">
              {_.replace(
                "##ctaKO##",
                Texts.agegate.ctaKO
              )(Texts.agegate.message)}
            </p>
            <div className="agegate-container-cta">
              <Button
                primary
                className="agegate-container-cta-ok"
                onClick={() =>
                  setCookie("agegate-acceptance", 1, { maxAge: 2592000 })
                }
              >
                {Texts.agegate.ctaOK}
              </Button>
              <Button
                color="red"
                className="agegate-container-cta-ko"
                onClick={() => window.history.back()}
              >
                {Texts.agegate.ctaKO}
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Agegate;
