export const ChannelsFilters = {
  filters: { open: "Filtros", close: "Cerrar filtros" },
  types: {
    all: "Todos los tipos",
  },
  cities: {
    all: "Todas las ciudades",
  },
  verified: "Post Verificado",
  premium: "Post Premium",
  search: "Buscar",
};

export default ChannelsFilters;
