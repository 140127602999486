import React from "react";
import PropTypes from "prop-types";
import { Texts } from "../../data/Data";
import parser from "html-react-parser";

const Legal = ({ section }) => {
  const sectionTexts = Texts.legal[section];
  return (
    <main className="legal">
      <h1>{sectionTexts.title}</h1>
      {parser(sectionTexts.content)}
    </main>
  );
};

Legal.propTypes = {
  section: PropTypes.string,
};

export default Legal;
