import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox, Button } from "semantic-ui-react";
import propTypes from "prop-types";

const FormText = ({
  fieldData: { name, id, value, texts, onChange, type, is_disabled },
}) => {
  return (
    <>
      <label htmlFor={id}>{texts.label}</label>
      <Input
        id={id}
        type={type ? type : "text"}
        name={name}
        disabled={is_disabled}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

FormText.propTypes = {
  fieldData: propTypes.object,
};

const FormCheckbox = ({ fieldData: { name, id, value, texts, onChange } }) => {
  return (
    <>
      <label htmlFor={id}>{texts.label}</label>
      <Checkbox id={id} name={name} onChange={onChange} checked={value} />
    </>
  );
};

FormCheckbox.propTypes = {
  fieldData: propTypes.object,
};

const FormHidden = ({ fieldData: { name, id, value, onChange } }) => {
  return (
    <Input
      id={id}
      type="hidden"
      name={name}
      value={value}
      onChange={onChange}
    />
  );
};

FormHidden.propTypes = {
  fieldData: propTypes.object,
};

const FormSubmit = ({ fieldData: { name, id, texts } }) => {
  return (
    <>
      <Button primary id={id} type="submit" name={name}>
        {texts.label}
      </Button>
    </>
  );
};

FormSubmit.propTypes = {
  fieldData: propTypes.object,
};

const SessionForm = ({ name, formFields, values, texts, onSubmit }) => {
  const [formValues, setFormValues] = useState(values);

  useEffect(() => {
    setFormValues(values);
  }, [values]);

  const handleChange = (data) => {
    const { name, value, checked } = data;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value || checked,
    }));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    onSubmit(formValues);
  };

  return (
    <Form name={`${name}-form`} onSubmit={handleOnSubmit}>
      {formFields.map((field, k) => {
        const fieldData = {
          ...field,
          texts: texts.form[field.name],
          value: formValues[field.name],
          id: `${name}-${field.name}`,
          onChange: (_e, data) => handleChange(data),
        };
        let element = null;
        switch (field.type) {
          case "text":
          case "email":
          case "tel":
          case "password":
            element = (
              <Form.Field key={k}>
                <FormText fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "checkbox":
            element = (
              <Form.Field key={k}>
                <FormCheckbox fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "hidden":
            element = <FormHidden key={k} fieldData={fieldData} />;
            break;

          case "submit":
            element = (
              <Form.Field key={k}>
                <FormSubmit fieldData={fieldData} />
              </Form.Field>
            );
            break;

          default:
            console.error(`No Field Format for ${field.type}`);
            element = null;
            break;
        }
        return element;
      })}
    </Form>
  );
};

SessionForm.propTypes = {
  name: propTypes.string,
  formFields: propTypes.array,
  values: propTypes.object,
  onSubmit: propTypes.func,
};

export default SessionForm;
