export const LegalCancellationReturnsAndShippingPolicy = {
  title: "POLITICA DE CANCELACIÓN, DEVOLUCIONES Y ENVÍOS",
  content: `
  <p><strong>POLÍTICA DE CANCELACIÓN Y DEVOLUCIONES</strong></p>
  
  <p class="has-small-font-size">Respecto a los servicios contratados y anuncios clasificados, nuestra política es la siguiente:<br><br>1- Una vez realizado el pago no estará permitida la cancelación del pedido.<br>2- No se admiten devoluciones ni se realizan reembolsos.<br>3- Para cualquier consulta enviar email a info@viptoenjoy.com</p>
  
  <p><strong>POLÍTICA DE ENVÍOS</strong></p>
  
  <p class="has-small-font-size">Respecto a los servicios contratados y anuncios clasificados, nuestra política de envíos es la siguiente:<br><br>1- Al ser productos virtuales, servicios y creación de anuncios clasificados y publicidad, no se realizan envíos físicos.<br>2- Los envíos son telemáticos a través de la red, correo electrónico y demás tecnología online.<br>3- El plazo del envío será el acordado previamente con el cliente.</p>
`,
};

export default LegalCancellationReturnsAndShippingPolicy;
