import React, { useState } from "react";
import _ from "lodash/fp";
import { PathRoutes, Texts } from "../../data/Data";
import { useLogin } from "../../data/api/useSession";
import SessionLoginForm from "../../data/forms/SessionLogin";
import SessionForm from "../../components/SessionForm";
import { Link } from "react-router-dom";
import { Button, Message } from "semantic-ui-react";

export const SessionLogin = ({ updateUserData, loginType }) => {
  const [showErrorMessage, setshowErrorMessage] = useState(false);
  const [loginFormValues, setloginFormValues] = useState({});
  const { trigger } = useLogin();

  const handleOnSubmit = (values) => {
    setloginFormValues(values);

    trigger(values).then((response) => {
      _.cond([
        [_.isNull, () => setshowErrorMessage(true)],
        [
          _.stubTrue,
          () => {
            const { alias, id, type } = _.first(response);
            const objectToStorage = { type: type || "manager", alias, id };

            localStorage.setItem(
              "session-context",
              JSON.stringify(objectToStorage)
            );
            updateUserData(objectToStorage);

            window.location.href =
              type === "user"
                ? PathRoutes.PATH_HOME
                : PathRoutes.PATH_ACCOUNT_MANAGER;
          },
        ],
      ])(response);
    });
  };

  return (
    <main className="session-login">
      <div className="session-login-heading">
        <h2 className="session-login-heading-title">
          {Texts.session.login.title}
        </h2>
      </div>
      <div className="session-login-content">
        <div className="session-login-content-block">
          <div className="session-login-content-block-title">
            <h4>{Texts.session.login.login.title}</h4>
          </div>
          <div className="session-login-content-block-singup">
            <h6>{Texts.session.login.login.singupTitle}</h6>
            <Button primary as={Link} to={PathRoutes.PATH_SESSION_SIGNUP_USER}>
              {Texts.session.login.login.singupCTA.user}
            </Button>
            <Button
              primary
              as={Link}
              to={PathRoutes.PATH_SESSION_SIGNUP_MANAGER}
            >
              {Texts.session.login.login.singupCTA.manager}
            </Button>
          </div>
          <div className="session-login-content-block-separator">
            <div className="session-login-content-block-separator-line"></div>
            <p>{Texts.session.login.divider}</p>
            <div className="session-login-content-block-separator-line"></div>
          </div>
          {showErrorMessage ? (
            <Message
              error
              header={Texts.session.login.messages.error.header}
              list={Texts.session.login.messages.error.list}
            />
          ) : null}
          <div className="session-login-content-block-login">
            <h5>{Texts.session.login.login.loginTitle}</h5>
            <SessionForm
              name="session-user"
              formFields={SessionLoginForm}
              values={{ ...loginFormValues, type: "user" }}
              onSubmit={handleOnSubmit}
              texts={Texts.session.login.login}
            />
            <div className="session-login-content-block-login-recovery">
              <Link
                to={PathRoutes.PATH_SESSION_PASSWORD_RECOVERY}
                className="session-login-content-block-login-recovery-link"
              >
                {Texts.session.login.passwordRecovery}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SessionLogin;
