const PATH_CHANNEL = "/:id";
const PATH_CHANNELS = "/canales";
const PATH_HOME = "/";
const PATH_CONTENTCREATORS = "/creadores-de-contenido";
const PATH_ABOUT = "/sobre-nosotros";
const PATH_PLATFORM = "/la-plataforma";
const PATH_ACCOUNT_USER = "/mi-cuenta";
const PATH_ACCOUNT_MANAGER = "/mis-canales";
const PATH_ACCOUNT_CHANNEL_NEW = "/mis-canales/nuevo-canal";
const PATH_ACCOUNT_CHANNEL_EDIT = "/mis-canales/:id";
const PATH_SESSION_LOGIN_USER = "/iniciar-sesion";
const PATH_SESSION_LOGIN_MANAGER = "/iniciar-sesion-anunciante";
const PATH_SESSION_PASSWORD_RECOVERY = "/recuperar-contrasena";
const PATH_SESSION_SIGNUP_USER = "/registro";
const PATH_SESSION_SIGNUP_MANAGER = "/registro-anunciante";
const PATH_SESSION_LOGOUT = "/cerrar-sesion";
const PATH_CREDITS = "/comprar-creditos";
const PATH_LEGAL_LEGALNOTICE = "/aviso-legal";
const PATH_LEGAL_CANCELLATIONRETURNSANDSHIPPINGPOLICY =
  "/politica-de-cancelaciones-devoluciones-y-envios";
const PATH_LEGAL_CONTENTPOLICY = "/politica-de-contenido";
const PATH_LEGAL_COOKIEPOLICY = "/politica-de-cookies";
const PATH_LEGAL_PRIVACYPOLICY = "/politica-de-privacidad";
const PATH_LEGAL_TERMSOFSERVICE = "/terminos-de-servicio";

export const PathRoutes = {
  PATH_CHANNEL,
  PATH_CHANNELS,
  PATH_HOME,
  PATH_CONTENTCREATORS,
  PATH_ABOUT,
  PATH_PLATFORM,
  PATH_ACCOUNT_USER,
  PATH_ACCOUNT_MANAGER,
  PATH_ACCOUNT_CHANNEL_NEW,
  PATH_ACCOUNT_CHANNEL_EDIT,
  PATH_SESSION_LOGIN_USER,
  PATH_SESSION_LOGIN_MANAGER,
  PATH_SESSION_PASSWORD_RECOVERY,
  PATH_SESSION_SIGNUP_USER,
  PATH_SESSION_SIGNUP_MANAGER,
  PATH_SESSION_LOGOUT,
  PATH_CREDITS,
  PATH_LEGAL_LEGALNOTICE,
  PATH_LEGAL_CANCELLATIONRETURNSANDSHIPPINGPOLICY,
  PATH_LEGAL_CONTENTPOLICY,
  PATH_LEGAL_COOKIEPOLICY,
  PATH_LEGAL_PRIVACYPOLICY,
  PATH_LEGAL_TERMSOFSERVICE,
};
