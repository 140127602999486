export const Credits = {
  hero: {
    preTitle: "Eleva tu placer",
    title: "Créditos",
    subTitle: "Accede a contenido exclusivo <br /> o potencia tus canales",
  },
  options: {
    credits: "Créditos",
    buy: "Comprar",
    save: "Ahorra",
    processing: "Procesando",
  },
};

export default Credits;
