import { PathRoutes } from "./PathRoutes";

const sections = [
  {
    title: "Inicio",
    slug: PathRoutes.PATH_HOME,
  },
  {
    title: "Amistad real",
    slug: PathRoutes.PATH_CHANNELS,
  },
  {
    title: "Método",
    slug: PathRoutes.PATH_PLATFORM,
  },
  {
    title: "Quiénes Somos",
    slug: PathRoutes.PATH_ABOUT,
  },
];

const userAnonimus = [
  {
    title: "Creadores de contenido",
    slug: PathRoutes.PATH_CONTENTCREATORS,
  },
  {
    title: "Únete",
    action: "showSignUpModal",
  },
  {
    title: "Iniciar Sesión",
    slug: PathRoutes.PATH_SESSION_LOGIN_USER,
  },
];

const userLogged = [
  {
    title: "Mi Cuenta",
    slug: PathRoutes.PATH_ACCOUNT_USER,
  },
  {
    title: "Cerrar Sesión",
    slug: PathRoutes.PATH_SESSION_LOGOUT,
  },
];

const managerLogged = [
  {
    title: "Mi Área",
    slug: PathRoutes.PATH_ACCOUNT_MANAGER,
  },
  {
    title: "Nuevo Canal",
    slug: PathRoutes.PATH_ACCOUNT_CHANNEL_NEW,
  },
  {
    title: "Cerrar Sesión",
    slug: PathRoutes.PATH_SESSION_LOGOUT,
  },
];

const user = {
  anonimus: userAnonimus,
  user: userLogged,
  manager: managerLogged,
};

export const Navigation = {
  sections: sections,
  user: user,
};
