import React, { useContext, useState } from "react";
import {
  Button,
  Container,
  Table,
  TableCell,
  Segment,
} from "semantic-ui-react";
import { PathRoutes, Texts } from "../../data/Data";
import AccountForm from "../../components/AccountForm";
import AccountUserForm from "../../data/forms/AccountUserForm";
import { useUser, useUserUpdate } from "../../data/api/useUser";
import { Link } from "react-router-dom";
import { sessionContext } from "../../App";
import MessageModal from "../../components/MessageModal";

const AccountUser = () => {
  const sessionData = useContext(sessionContext);
  const id = sessionData.alias;
  const { user } = useUser(id);
  const { trigger } = useUserUpdate(user.id);
  const [open, setOpen] = useState(false);

  const handleOnSubmit = (values) => {
    trigger(values).then((response) => {
      setOpen(true);
    });
  };

  return (
    <Container className="account-user">
      <h2>{Texts.account.user.title}</h2>
      <Segment className="account-user-info">
        <h3>{Texts.account.user.info.title}</h3>
        <AccountForm
          name="account-user"
          formFields={AccountUserForm}
          values={user}
          onSubmit={handleOnSubmit}
          texts={Texts.account.user.info}
        />
      </Segment>
      <MessageModal
        open={open}
        texts={Texts.account.user.messages.success}
        action={() => window.location.reload()}
        onClose={() => window.location.reload()}
      />
      <Segment className="account-user-subscriptions">
        <h3>{Texts.account.user.subscriptions.title}</h3>
        <div className="account-user-subscriptions-table">
          <Table>
            <Table.Header>
              <Table.Row>
                <TableCell>
                  {Texts.account.user.subscriptions.tableHeaders.channel}
                </TableCell>
                <TableCell>
                  {Texts.account.user.subscriptions.tableHeaders.since}
                </TableCell>
                <TableCell>
                  {Texts.account.user.subscriptions.tableHeaders.months}
                </TableCell>
                <TableCell>
                  {Texts.account.user.subscriptions.tableHeaders.expiring}
                </TableCell>
                <TableCell>
                  {Texts.account.user.subscriptions.tableHeaders.type}
                </TableCell>
                <TableCell></TableCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <TableCell>Avatar canal + Canal1</TableCell>
                <TableCell>25 febrero 2023</TableCell>
                <TableCell>5 meses (2 meses)</TableCell>
                <TableCell>15 marzo 2024</TableCell>
                <TableCell>Renovable</TableCell>
                <TableCell>
                  Activar autorenovación | Renovar | Cancelar
                </TableCell>
              </Table.Row>
              <Table.Row>
                <TableCell>Avatar canal + Canal1</TableCell>
                <TableCell>25 febrero 2023</TableCell>
                <TableCell>5 meses (2 meses)</TableCell>
                <TableCell>15 marzo 2024</TableCell>
                <TableCell>Mensual</TableCell>
                <TableCell>
                  Activar autorenovación | Renovar | Cancelar
                </TableCell>
              </Table.Row>
              <Table.Row>
                <TableCell>Avatar canal + Canal1</TableCell>
                <TableCell>25 febrero 2023</TableCell>
                <TableCell>5 meses (2 meses)</TableCell>
                <TableCell>15 marzo 2024</TableCell>
                <TableCell>Premium</TableCell>
                <TableCell>
                  Activar autorenovación | Renovar | Cancelar
                </TableCell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </Segment>
      <Segment className="account-user-wallet">
        <h3>{Texts.account.user.wallet.title}</h3>
        <Button primary as={Link} to={PathRoutes.PATH_CREDITS}>
          {Texts.account.user.wallet.buy}
        </Button>
        <Table>
          <Table.Header>
            <Table.Row>
              <TableCell>
                {Texts.account.user.wallet.tableHeaders.date}
              </TableCell>
              <TableCell>
                {Texts.account.user.wallet.tableHeaders.for}
              </TableCell>
              <TableCell>
                {Texts.account.user.wallet.tableHeaders.used}
              </TableCell>
              <TableCell>
                {Texts.account.user.wallet.tableHeaders.total}
              </TableCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <TableCell>25 octubre 2023</TableCell>
              <TableCell>Suscripción a Canal1</TableCell>
              <TableCell>-1 crédito</TableCell>
              <TableCell>3</TableCell>
            </Table.Row>
            <Table.Row>
              <TableCell>20 octubre 2023</TableCell>
              <TableCell>Renovación a Canal1</TableCell>
              <TableCell>-1 crédito</TableCell>
              <TableCell>4</TableCell>
            </Table.Row>
            <Table.Row>
              <TableCell>5 octubre 2023</TableCell>
              <TableCell>Compra de créditos 5</TableCell>
              <TableCell>+5 créditos (VLVL22019ASDD987)</TableCell>
              <TableCell>5</TableCell>
            </Table.Row>
            <Table.Row>
              <TableCell>20 octubre 2023</TableCell>
              <TableCell>Renovación a Canal1</TableCell>
              <TableCell>-1 crédito</TableCell>
              <TableCell>0 créditos</TableCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    </Container>
  );
};

export default AccountUser;
