const AccountManagerForm = [
  { name: "avatar", type: "avatar" },
  { name: "email", type: "email", is_disabled: true },
  { name: "alias", type: "text" },
  { name: "name", type: "text" },
  { name: "surname", type: "text" },
  { name: "phone", type: "tel" },
  { name: "phone2", type: "tel" },
  { name: "whatsapp", type: "tel" },
  { name: "new-password", type: "new-password" },
  { name: "submit", type: "submit" },
];

export default AccountManagerForm;
