import React from "react";

import { map } from "lodash/fp";
import parse from "html-react-parser";

import { Imgs, PathRoutes, Texts } from "../../data/Data";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const Platform = (props) => {
  return (
    <main className="platform">
      <div
        className="platform-hero"
        style={{ backgroundImage: `url(${Imgs.platform.hero})` }}
      >
        <div className="platform-hero-content">
          <h5>{Texts.platform.hero.preTitle}</h5>
          <h1>{Texts.platform.hero.title}</h1>
        </div>
      </div>

      <div className="platform-content">
        <div className="platform-content-block">
          <div className="platform-content-block-image">
            <img
              className="platform-content-block-image-img"
              src={Imgs.platform.content[0]}
              alt=""
            />
          </div>
          <div className="platform-content-block-text">
            <h3>{Texts.platform.content[0].title}</h3>
            <p>{Texts.platform.content[0].description}</p>
          </div>
        </div>
        <div className="platform-content-block">
          <div className="platform-content-block-image">
            <img
              className="platform-content-block-image-img"
              src={Imgs.platform.content[1]}
              alt=""
            />
          </div>
          <div className="platform-content-block-text">
            <h3>{Texts.platform.content[1].title}</h3>
            <p>{Texts.platform.content[1].description}</p>
          </div>
        </div>
      </div>

      <div className="platform-features">
        <div className="platform-features-content">
          {map.convert({ cap: false })((feature, k) => (
            <div className="platform-features-content-feature" key={k}>
              <div className="platform-features-content-feature-content">
                <img
                  className="platform-features-content-feature-content-icon"
                  src={Imgs.platform.features}
                  alt=""
                />
                <h4 className="platform-features-content-feature-content-title">
                  {parse(feature.text)}
                </h4>
                <p className="platform-features-content-feature-content-description">
                  {feature.description}
                </p>
              </div>
            </div>
          ))(Texts.platform.features)}
        </div>
      </div>

      <div
        className="platform-highlight"
        style={{ backgroundImage: `url(${Imgs.platform.highlight})` }}
      >
        <div className="platform-highlight-content">
          <h3>{parse(Texts.platform.higlight.text)}</h3>
          <Button primary as={Link} to={PathRoutes.PATH_CHANNELS}>
            {Texts.platform.higlight.cta}
          </Button>
        </div>
      </div>
    </main>
  );
};

Platform.propTypes = {};

export default Platform;
