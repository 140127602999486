export const SessionLogin = {
  title: "Accede a un mundo de pasión sin límites",
  divider: "¿Ya tienes cuenta?",
  login: {
    title: "Tu tienes la llave de la puerta al placer",
    singupTitle: "Crea tu cuenta",
    singupCTA: {
      user: "Registrarme como Usuario",
      manager: "Registrarme con Anunciante",
    },
    loginTitle: "Inicia sesión",
    form: {
      user: { label: "Email Usuario" },
      password: { label: "Contraseña" },
      submit: { label: "Enviar" },
    },
  },
  passwordRecovery: "Recuperar contraseña",
  messages: {
    error: {
      header: "Los datos de acceso no son correctos",
      list: [
        "Comprueba que el email de usuario es correcto",
        "Comprueba que la contraseña es correcta",
      ],
    },
  },
};

export default SessionLogin;
