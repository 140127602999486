import _ from "lodash/fp";
import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  List,
} from "semantic-ui-react";

const MessageModal = ({ open, texts, action, actions, onClose }) => {
  return (
    <Modal onClose={onClose} open={open}>
      <ModalHeader>{texts.header}</ModalHeader>
      <ModalContent>
        {_.map((content) =>
          _.cond([
            [_.isArray, (list) => <List bulleted items={list}></List>],
            [_.stubTrue, (paragraph) => <p>{paragraph}</p>],
          ])(content)
        )(texts.content)}
      </ModalContent>
      <ModalActions>
        {!actions && action ? (
          <Button content={texts.actions.ok} onClick={action} primary />
        ) : actions ? (
          _.map((action) => (
            <Button
              key={action.action}
              content={action.content}
              onClick={action.action}
              primary
            />
          ))(actions)
        ) : null}
      </ModalActions>
    </Modal>
  );
};

MessageModal.propTypes = {
  open: PropTypes.bool,
  texts: PropTypes.object,
  action: PropTypes.func,
  actions: PropTypes.array,
  onClose: PropTypes.func,
};

export default MessageModal;
