import React, { useState } from "react";
import { PathRoutes, Texts } from "../../data/Data";
import { useSignup } from "../../data/api/useSession";
import SessionSignupForm from "../../data/forms/SessionSignup";
import SessionForm from "../../components/SessionForm";
import _ from "lodash/fp";
import { Message } from "semantic-ui-react";
import MessageModal from "../../components/MessageModal";

const SessionSignup = () => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [aliasSuggestion, setAliasSuggestion] = useState("");
  const [loginFormValues, setLoginFormValues] = useState({});
  const [open, setOpen] = useState(false);
  const { trigger } = useSignup("user");
  const handleOnSubmit = (values) => {
    setLoginFormValues(values);
    trigger(values).then((response) => {
      _.cond([
        [
          ({ exists }) => exists === "yes",
          ({ alternative }) => {
            setAliasSuggestion(alternative);
            setShowErrorMessage(true);
          },
        ],
        [_.stubTrue, () => setOpen(true)],
      ])(response);
    });
  };
  return (
    <main className="session-signup">
      <div className="session-signup-heading">
        <h2 className="session-signup-heading-title">
          {Texts.session.signup.user.title}
        </h2>
      </div>
      <div className="session-signup-content">
        <div className="session-signup-content-block">
          <div className="session-signup-content-block-title">
            <h4>{Texts.session.signup.user.blockTitle}</h4>
          </div>
          <div className="session-signup-content-block-signup">
            <h5>{Texts.session.signup.user.signupTitle}</h5>
            {showErrorMessage ? (
              <Message
                error
                header={Texts.session.signup.user.messages.error.header}
                list={_.map((item) =>
                  _.replace("##available__alias##", aliasSuggestion)(item)
                )(Texts.session.signup.user.messages.error.list)}
              />
            ) : null}
            <SessionForm
              name="session-user"
              formFields={SessionSignupForm}
              values={{ ...loginFormValues, type: "user" }}
              onSubmit={handleOnSubmit}
              texts={Texts.session.signup.user}
            />
          </div>
        </div>
      </div>
      <MessageModal
        open={open}
        texts={Texts.session.signup.user.messages.success}
        action={() =>
          (window.location.href = PathRoutes.PATH_SESSION_LOGIN_USER)
        }
        onClose={() =>
          (window.location.href = PathRoutes.PATH_SESSION_LOGIN_USER)
        }
      />
    </main>
  );
};

export default SessionSignup;
