import { Imgs } from "../Imgs";

export const Home = {
  hero: {
    preTitle: "El Nirvana del entretenimiento adulto",
    title: "VivaLaVidaLoca",
    subTitle: "Contenido sin tabúes <br />real, potente y épico",
    cta: "Descubre más",
  },
  features: [
    { text: "Disfrute superior", bg: Imgs.home.features[0] },
    { text: "Placeres más elevados", bg: Imgs.home.features[1] },
    { text: "Aprendizaje experto", bg: Imgs.home.features[2] },
    { text: "Interacción real", bg: Imgs.home.features[3] },
  ],
  services: {
    title:
      "La plataforma para quienes buscan llevar a otro nivel los verdaderos placeres de la vida",
    subtitle:
      "Un ludiverso de entretenimiento para adultos a otro nivel que te da acceso a",
    services: [
      { text: "Red de amistad y relaciones", icon: Imgs.home.services[0] },
      { text: "Creadores de contenido XXX real", icon: Imgs.home.services[1] },
      { text: "Expertos en placeres", icon: Imgs.home.services[2] },
      { text: "Sex shop y perfumería de placer", icon: Imgs.home.services[3] },
    ],
  },
  higlight: {
    text: "Para quienes buscan <br />los placeres más elevados",
    cta: "Descubre más",
  },
  closing: {
    title: "La única plataforma creada totalmente en español",
    subtitle: "Un espacio para crear y consumir contenido para adultos",
  },
};

export default Home;
