export const LegalContentPolicy = {
  title: "POLITICA DE CONTENIDOS DE VIPTOENJOY.COM",
  content: `
  <p>Esta Política de Publicación de Contenidos guía el tipo de contenido que se puede publicar en nuestra plataforma. Nos reservamos el derecho de eliminar cualquier contenido que no cumpla con estas directrices o la ley aplicable.</p>
  
  <p><strong>Intentar evadir estas normas y el sistema automático de control de texto y fotos puede conllevar el bloqueo de la cuenta.</strong></p>
  
  <p><strong>Intentar esconder/publicar&nbsp;palabras/fotos es motivo de bloqueo de cuenta.</strong></p>

  <p>Si detectamos contenido que sugiere la trata de personas o menores, tomaremos medidas inmediatas suspendiendo la cuenta en cuestión e informando a las autoridades pertinentes.</p>
  
  <p>Es importante recordar que&nbsp;<strong>todo contenido que infrinja nuestras políticas no será aceptado ni publicado en la plataforma</strong>. A continuación, se muestra una lista no exhaustiva de tipos de&nbsp;<strong>contenidos que no se permiten</strong>, ya sean fotos o textos:&nbsp;</p>
  
  <p>1- Contenido que involucre&nbsp;<strong>menores de edad</strong>&nbsp;en cualquier contexto.</p>
  
  <p>2- Cualquier material que promueva, oculte o insinúe la&nbsp;<strong>trata de personas o la explotación sexual</strong>.</p>
  
  <p>3- Imágenes o textos que representen violencia, actos denigrantes o humillantes.</p>
  
  <p>4- Contenido que promueva, justifique o incite la discriminación por razones de género, raza, origen, nacionalidad, religión, discapacidad, edad, orientación sexual o cualquier otra característica personal.</p>
  
  <p>5- Contenido que promueva, muestre o aluda a&nbsp;<strong>drogas ilegales</strong>,&nbsp;<strong>medicamentos</strong>&nbsp;no prescritos, o que pretenda serlo.</p>
  
  <p>6- Contenido que promueva conductas perjudiciales para la salud, la seguridad o el medio ambiente.</p>
  
  <p>7- Imágenes o representaciones explícitas de&nbsp;<strong>material sexualmente explícito</strong>, incluyendo pero no limitado a la&nbsp;<strong>exhibición de genitales, pezones, juguetes sexuales, emoticonos en zonas sexuales, tocamiento de zonas íntimas, posturas encima de cama.</strong></p>
  
  <p>8- Uso de estereotipos de género que trivializan la violencia sexual.</p>
  
  <p>9-&nbsp;<strong>Ofertas de servicios sexuales</strong>&nbsp;o propuestas para servicios sexuales.</p>
  
  <p>10- Uso de fotos o<strong>&nbsp;imágenes que no sean auténticas</strong>, que hayan sido alteradas, que incluyan marcas de agua ajenas, capturas de pantalla, o cualquier otra imagen que no sea original y propiedad del anunciante.</p>
  
  <p>11- Contenido que represente o involucre a&nbsp;<strong>personas sin su consentimiento explícito</strong>&nbsp;y documentado.</p>
  
  <p>12- Cualquier material que sea ilegal, fraudulento, engañoso, amenazante, difamatorio o que incite al odio, la violencia o la discriminación hacia individuos o grupos.</p>
  
  <p>13- Uso de texto con palabras clave repetitivas, spam, o cualquier otro método diseñado para manipular a la plataforma o sus usuarios.</p>
  
  <p>14- Perfiles que incluyan textos ilegibles,&nbsp;<strong>contenido inapropiado para la categoría del anuncio</strong>, o cualquier uso de marcas de agua de terceros o capturas de pantalla.</p>
  
  <p>15-&nbsp;<strong>Cualquier intento de evadir las restricciones</strong>&nbsp;o regulaciones de la plataforma, como el uso de lenguaje codificado o simbólico para representar actividades prohibidas.</p>
  
  <p>16- La promoción de actividades ilegales o inseguras, incluyendo pero no limitado a la venta de armas, actividades de juego ilegales, o estafas financieras.</p>
  
  <p>17- Uso indebido de la&nbsp;<strong>información personal de otros</strong>, incluyendo doxing, acoso o cualquier otra violación de la privacidad.</p>
  
  <p>A continuación detallamos las<strong>&nbsp;fotos que no son permitidas:</strong></p>
  
  <ul>
  <li>– Uso o alusión a&nbsp;<strong>drogas</strong>, incluyendo pero no limitado a marihuana y alcohol.</li>
  </ul>
  
  <ol>
  <li>– Imágenes con&nbsp;<strong>contenido sexual</strong>&nbsp;explícito, es decir, aquellas que muestren zonas íntimas.</li>
  
  <li>– Imágenes que impliquen&nbsp;<strong>erecciones</strong>, incluso si están cubiertas por la ropa.</li>
  
  <li>– Gestos o poses que sugieran&nbsp;<strong>tocarse las zonas íntimas</strong>.</li>
  
  <li>– Uso de filtros, iconos o&nbsp;<strong>emojis</strong>&nbsp;para cubrir o señalar&nbsp;<strong>zonas íntimas</strong>.</li>
  
  <li>– Uso de iconos,&nbsp;<strong>emojis</strong>&nbsp;o imágenes que tengan connotaciones&nbsp;<strong>sexuales</strong>, como&nbsp;<strong>fiestas</strong>, lenguas, pepinos, melones, etc.</li>
  
  <li>– Poses que sugieran actividades sexuales, estar<strong>&nbsp;en “cuatro”, poses encima de cama o muebles.</strong></li>
  </ol>
  
  <ol>
  <li>– Referencias a transacciones financieras, ya sea&nbsp;<strong>dinero</strong>&nbsp;en efectivo, transferencias por Bizum.</li>
  
  <li>–&nbsp;<strong>Textos</strong>&nbsp;con palabras de connotación sexual.</li>
  
  <li>– Imágenes que muestren&nbsp;<strong>ropa ajustada, transparencias o mallas</strong>&nbsp;donde se pueda ver o marcar zonas íntimas.</li>
  
  <li>– Imágenes sugestivas con&nbsp;<strong>piruletas</strong>,&nbsp;<strong>helados</strong>, dedos, etc., en una connotación sexual.</li>
  
  <li>– Imágenes de personas&nbsp;<strong>consumiendo alimentos de manera sugerente</strong>, incluyendo frutas, leche, etc.</li>
  
  <li><strong>– Primeros planos de genitales, pechos, culo</strong>, incluso si no se ve nada explícito.</li>
  
  <li>– Fotografías con poses que parezcan no naturales o sugestivas, como una mujer con las&nbsp;<strong>piernas abiertas</strong>, aunque no se vea nada explícito.</li>
  
  <li>– Fotos de&nbsp;<strong>desnudos en las que las zonas íntimas estén cubiertas</strong>&nbsp;de manera creativa, como con espuma en una bañera, o tapando un topless con las manos, etc.</li>
  
  <li>– Imágenes de&nbsp;<strong>ropa íntima, preservativos, juguetes sexuales</strong>&nbsp;o equipos&nbsp;<strong>BDSM</strong>.</li>
  </ol>
  
  <p>Todos los perfiles que se activen o editen serán revisados automáticamente antes de ser publicados. Una vez publicado,&nbsp;<strong>el perfil será revisado adicionalmente por nuestros moderadores</strong>. Este control exhaustivo se realiza para garantizar la seguridad y el respeto entre todos los miembros de nuestra plataforma.</p>
  
  <p>Al tener un sistema completamente automatizado que detecta palabras y fotos no permitidas y las acepta o no al instante, lleva a algunos problemas de usuarios que no cumplen las normas, en caso de que el usuario intente evadir el sistema de detección, será bloqueado. Si el texto es aceptado por el sistema automático y nuestros moderadores editan o moderan el texto,&nbsp;<strong>si vuelves a subir el contenido prohibido se bloqueará tu cuenta</strong>, al igual que si tu foto es marcada en rojo, la borras y la vuelves a subir, tu usuario será bloqueado.</p>
  
  <p><strong>Intentar evadir estas normas y el sistema automático de control de texto y fotos puede conllevar el bloqueo de la cuenta.</strong></p>
`,
};

export default LegalContentPolicy;
