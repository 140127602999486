import React from "react";
import Logo from "../components/Logo";
import FooterNavigation from "../data/FooterNavigation";
import _ from "lodash/fp";
import { Icon } from "semantic-ui-react";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-content-left">
          <div className="footer-content-left-logo">
            <Logo />
          </div>
          <div className="footer-content-left-social">
            <ul className="footer-content-left-social-list">
              {_.map(({ title, url }) => (
                <li
                  key={_.trim(title)}
                  className="footer-content-left-social-list-item"
                >
                  <a href={url}>
                    <Icon size="large" name={_.toLower(title)} />
                  </a>
                </li>
              ))(FooterNavigation.social)}
            </ul>
          </div>
        </div>
        <div className="footer-content-right">
          <div className="footer-content-right-legal">
            <ul className="footer-content-right-legal-list">
              {_.map(({ title, url }) => (
                <li
                  key={_.trim(title)}
                  className="footer-content-right-legal-list-item"
                >
                  <a href={url}>{title}</a>
                </li>
              ))(FooterNavigation.legal)}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
