import React, { useContext, useEffect, useState } from "react";
import { Navigation, PathRoutes, Texts } from "../data/Data";

import { Logo } from "../components/Logo";
import { sessionContext } from "../App";
import MessageModal from "../components/MessageModal";

export const Header = (actualPath) => {
  const userLogged = useContext(sessionContext);
  const [scrollState, setScrollState] = useState(window.scrollY > 0);
  const [navigationOpened, setNavigationOpened] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);

  const userMenu = userLogged
    ? Navigation.user[userLogged.type]
    : Navigation.user.anonimus;

  const handleNavigationToggle = () => setNavigationOpened(!navigationOpened);

  useEffect(() => {
    const handleScroll = () => {
      setScrollState(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="app-header">
      <div className={`app-header-container ${scrollState ? "scrolling" : ""}`}>
        <div className="app-header-container-logo">
          <Logo />
        </div>
        <nav className="app-header-container-nav">
          <div
            className="app-header-container-nav-toggler"
            onClick={handleNavigationToggle}
          >
            <div className="app-header-container-nav-toggler-burger">
              <div className="app-header-container-nav-toggler-burger-line"></div>
              <div className="app-header-container-nav-toggler-burger-line"></div>
              <div className="app-header-container-nav-toggler-burger-line"></div>
            </div>
          </div>
          <div
            className={`app-header-container-nav-navigation ${
              navigationOpened ? "opened" : ""
            }`}
          >
            <ul className="app-header-container-nav-navigation-list">
              {Navigation.sections.map((menuItem, k) => (
                <li
                  key={`sections-${k}`}
                  className="app-header-container-nav-navigation-list-item"
                >
                  <a href={menuItem.slug}>{menuItem.title}</a>
                </li>
              ))}
            </ul>
            <ul className="app-header-container-nav-navigation-list">
              {userMenu.map((menuItem, k) => (
                <li
                  key={`session-${k}`}
                  className="app-header-container-nav-navigation-list-item"
                >
                  {menuItem.slug ? (
                    <a href={menuItem.slug}>{menuItem.title}</a>
                  ) : menuItem.action === "showSignUpModal" ? (
                    <a href="#" onClick={() => setOpenSignup(true)}>
                      {menuItem.title}
                    </a>
                  ) : null}
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
      <MessageModal
        open={openSignup}
        texts={Texts.navigation.messages.signup}
        actions={[
          {
            content: Texts.navigation.messages.signup.actions.user,
            action: () =>
              (document.location.href = PathRoutes.PATH_SESSION_SIGNUP_USER),
          },
          {
            content: Texts.navigation.messages.signup.actions.manager,
            action: () =>
              (document.location.href = PathRoutes.PATH_SESSION_SIGNUP_MANAGER),
          },
        ]}
        onClose={() => setOpenSignup(false)}
      />
    </header>
  );
};

export default Header;
