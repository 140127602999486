import useSWRMutation from "swr/mutation";
import { API_CREDITS_BUY } from "./endpoints";

export const useCreditsBuy = () => {
  const url = API_CREDITS_BUY;
  const fetcher = (url, payload) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((r) => {
      return r.json();
    });
  };
  return useSWRMutation(url, (url, { arg }) => {
    return fetcher(url, arg);
  });
};
